import { useRef, useState } from "react";
import { CyclePicture } from "../../components/ListPicture";
import PrayerCycleFormEditor from "./v2";
import { formatDate, offsetDate } from "../../lib";
import PrayerListParser from "../../components/PrayerListParser";
import BaseDialog from "../../components/BaseDialog";
import { ReactComponent as ChevronLeftIcon } from "../../icons/cheveron_left.svg";
import { ReactComponent as ChevronRightIcon } from "../../icons/chevron_right.svg";
import { ReactComponent as EditIcon } from "../../icons/my_prayer_list.svg";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from "@reach/menu-button";
import Portal from "@reach/portal";
import FormField from "../../components/FormField";
import {
  deletePrayerCycle,
  publishPrayerCycle,
  savePrayerCycle,
  transferPrayerCycle,
  unpublishPrayerCycle,
  updateCycleLogo,
} from "../../api";
import { useToasts } from "../../contexts/ToastContext";
import { queryClient } from "../../App";
import { ReactComponent as MenuIcon } from "../../icons/vertical_dots_condensed.svg";
import Upload from "../../components/ChangePictureButton";
import { BaseMenuItem, BaseMenuList } from "../../components/BaseMenu";
import SelectPerson from "../../components/SelectPerson";
import { Dropzone } from "../../components/editorStuff";
import "./styles.css";
import Spinner from "../../components/Spinner";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import ProfilePicture from "../../components/ProfilePicture";
import { useAccount } from "../../AccountContext";
import { useTitle } from "../../hooks";
import { useNavigate } from "react-router-dom-v5-compat";

const BLANK_PRAYER_CYCLE = {
  is_public: false,
  short_title: "",
  long_title: "",
  owner: true,
  description: "",
  organisation_name: "",
  feature_color: "rgb(0,0,0)", //unused
  start_date: "2021-01-01",
  has_image: true,
  logo_url: "",
  prayers_json: [],
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function PrayerCycleEditor({ prayerCycle }) {
  const { toast } = useToasts();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [picIsLoading, setPicIsLoading] = useState(false);
  const navigate = useNavigate();
  const [cycle, _setCycle] = useState(() => {
    if (!prayerCycle) {
      return BLANK_PRAYER_CYCLE;
    }
    if (!typeof prayerCycle.prayers_json === "object") {
      return {
        ...prayerCycle,
        prayers_json: [],
      };
    }
    prayerCycle.prayers_json = prayerCycle.prayers_json.map((prayer) =>
      prayer.map((section) => ({
        ...section,
        text: section.editable_text,
      }))
    );
    return prayerCycle;
  });
  useTitle(cycle?.short_title ? `Editing ${cycle?.short_title}` : "");

  const logoRef = useRef();
  const [b64, setb64] = useState("");

  const setFile = async () => {
    const file = logoRef.current.files[0];
    // console.log(file);
    setPicIsLoading(true);
    toBase64(file).then((res) => {
      setb64(res);
      updateCycleLogo(cycle.prayer_cycle_id, res)
        .then(() => {
          setPicIsLoading(false);
          _setCycle((c) => ({
            ...c,
            has_image: true,
            version_number: Number(c.version_number || 0) + 1,
          }));
        })
        .catch(() => {
          setPicIsLoading(false);
        });
    });
  };
  const handlePic = async (file) => {
    if (file.type !== "image/png" && file.type !== "image/jpeg") return;
    // console.log(file);
    setPicIsLoading(true);
    toBase64(file).then((res) => {
      setb64(res);
      updateCycleLogo(cycle.prayer_cycle_id, res)
        .then(() => {
          setPicIsLoading(false);
          _setCycle((c) => ({
            ...c,
            has_image: true,
            version_number: Number(c.version_number || 0) + 1,
          }));
        })
        .catch(() => {
          setPicIsLoading(false);
        });
    });
  };

  const setCycle = (arg) => {
    setHasUnsavedChanges(true);
    _setCycle(arg);
  };

  const saveEverything = () => {
    if (!cycle || !cycle.prayer_cycle_id) {
      return;
    }
    savePrayerCycle(cycle.prayer_cycle_id, {
      short_title: cycle.short_title,
      long_title: cycle.long_title,
      description: cycle.description,
      organisation_name: cycle.organisation_name,
      feature_color: cycle.feature_color,
      start_date: cycle.start_date,
      end_date: cycle.end_date,
      prayers_json: cycle.prayers_json || [],
    })
      .then((res) => {
        toast.add("Saved!");
        queryClient.removeQueries(["feed", "cycles", cycle.prayer_cycle_id], {
          exact: true,
        });
        setHasUnsavedChanges(false);
      })
      .catch((err) =>
        toast.error(
          <span>
            Sorry, an error occured:{" "}
            <code>{err.message || "no error code"}</code>
          </span>
        )
      );
  };

  const save = (parts) => {
    console.log("hi");
    if (!cycle || !cycle.prayer_cycle_id) {
      return;
    }
    savePrayerCycle(cycle.prayer_cycle_id, parts)
      .then((res) => {
        toast.add("Saved!");
        queryClient.removeQueries(["feed", "cycles", cycle.prayer_cycle_id], {
          exact: true,
        });
        setHasUnsavedChanges(false);
      })
      .catch((err) =>
        toast.error(
          <span>
            Sorry, an error occured:{" "}
            <code>{err.message || "no error code"}</code>
          </span>
        )
      );
  };

  const makePublic = () => {
    if (!cycle || !cycle.prayer_cycle_id) {
      return;
    }
    if (!cycle.has_image) {
      return toast.error(
        <span>Sorry, a logo is required to publish your prayer cycle</span>
      );
    }
    publishPrayerCycle(cycle.prayer_cycle_id)
      .then((res) => {
        toast.add("Done!");
        setCycle({
          ...cycle,
          is_public: true,
        });
        setHasUnsavedChanges(false);
      })
      .catch((err) =>
        toast.error(
          <span>
            Sorry, an error occured:{" "}
            <code>{err.message || "no error code"}</code>
          </span>
        )
      );
  };
  const makePrivate = () => {
    if (!cycle || !cycle.prayer_cycle_id) {
      return;
    }
    unpublishPrayerCycle(cycle.prayer_cycle_id)
      .then((res) => {
        toast.add("Done!");
        setCycle({
          ...cycle,
          is_public: false,
        });
        setHasUnsavedChanges(false);
      })
      .catch((err) =>
        toast.error(
          <span>
            Sorry, an error occured:{" "}
            <code>{err.message || "no error code"}</code>
          </span>
        )
      );
  };
  const deleteCycle = () => {
    if (!cycle || !cycle.prayer_cycle_id) {
      return;
    }
    deletePrayerCycle(cycle.prayer_cycle_id)
      .then((res) => {
        navigate("/prayer-cycle", { replace: true });
      })
      .catch((err) =>
        toast.error(
          <span>
            Sorry, an error occured:{" "}
            <code>{err.message || "no error code"}</code>
          </span>
        )
      );
  };

  const startDate = new Date(cycle.start_date);
  startDate.setUTCMinutes(
    startDate.getUTCMinutes() + startDate.getTimezoneOffset()
  );

  const handlePropertyChange = (key) => {
    return function (e) {
      setCycle((old) => {
        return {
          ...old,
          [key]: e.target.value,
        };
      });
    };
  };

  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [currentlyEditingIdx, setCurrentlyEditingIdx] = useState(0);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  const edit = (idx) => {
    setCurrentlyEditingIdx(idx);
    open();
  };
  const handlePrayerChange = (next) => {
    setCycle((c) => {
      const prayers = c.prayers_json.slice();
      prayers.splice(currentlyEditingIdx, 1, next);
      save({ prayers_json: prayers });
      return {
        ...c,
        prayers_json: prayers,
      };
    });
    requestAnimationFrame(() => {
      // save({ prayers_json: cycle.prayers_json });
    });
  };

  const moveUp = (idx) => {
    if (idx === 0) {
      return;
    }
    setCycle((c) => {
      const prayers = c.prayers_json.slice();
      var prayer = prayers[idx];
      prayers.splice(idx, 1);
      prayers.splice(idx - 1, 0, prayer);
      return {
        ...c,
        prayers_json: prayers,
      };
    });
    requestAnimationFrame(() => {
      document.getElementById(`prayer-${idx - 1}`).scrollIntoView();
      save({ prayers_json: cycle.prayers_json });
    });
  };

  const moveDown = (idx) => {
    if (idx === cycle.prayers_json.length - 1) {
      return;
    }
    setCycle((c) => {
      const prayers = c.prayers_json.slice();
      var prayer = prayers[idx];
      prayers.splice(idx, 1);
      prayers.splice(idx + 1, 0, prayer);
      return {
        ...c,
        prayers_json: prayers,
      };
    });
    requestAnimationFrame(() => {
      document.getElementById(`prayer-${idx + 1}`).scrollIntoView();
      save({ prayers_json: cycle.prayers_json });
    });
  };

  const remove = (idx) => {
    setCycle((c) => {
      const prayers = c.prayers_json.slice();
      prayers.splice(idx, 1);
      return {
        ...c,
        prayers_json: prayers,
      };
    });
    requestAnimationFrame(() => {
      save({ prayers_json: cycle.prayers_json });
    });
  };

  const addPrayer = () => {
    const addedIdx = cycle.prayers_json.length;
    setCycle((c) => {
      const prayers = c.prayers_json.slice();
      prayers.push([{ type: "paragraph", text: "" }]);
      return {
        ...c,
        prayers_json: prayers,
      };
    });
    requestAnimationFrame(() => {
      edit(addedIdx);
    });
  };
  const { account } = useAccount();

  return (
    <div>
      {cycle.author_id != account.userInfo.id && (
        <Redirect to={`/friends/prayer-cycle/${cycle.prayer_cycle_id}`} />
      )}
      <div className="width-wrapper EditorPage_wrapper">
        <div className="pb-4" />
        <div className="card">
          <div className="pb-3">
            <Dropzone className="ProfilePicDropzone" onDrop={handlePic}>
              <CyclePicture
                hasImage={cycle.has_image}
                id={cycle.prayer_cycle_id}
                version={cycle.image_version}
                size="xl"
                color={cycle.feature_color}
                name={cycle.short_title.trim()}
              />
              {picIsLoading && (
                <div className="ProfilePicLoading">
                  <Spinner />
                </div>
              )}
            </Dropzone>
            <div className="pb-3" />
            <Upload
              containerClassName=""
              className="outlined"
              ref={logoRef}
              onChange={setFile}
            >
              <EditIcon className="inline-icon" width="24" height="24" />{" "}
              {cycle.has_image ? "Change Logo" : "Add Logo"}
            </Upload>
          </div>
          {/* <small>
          <small>{b64}</small>
        </small> */}
          <div className=" pb-3 flex-row align-center">
            <h1 className="m-0 flex-1">
              {cycle.short_title || "New Prayer Cycle"}{" "}
              {!cycle.is_public && (
                <span style={{ fontWeight: "300" }}>(Draft)</span>
              )}
            </h1>
            {/* <small>
          <pre>
            <code>{JSON.stringify(cycle, null, 2)}</code>
          </pre>
        </small> */}
            {!cycle.is_public && (
              <button
                className={cycle.has_image ? "solid" : "text"}
                // disabled={!cycle.has_image}
                onClick={makePublic}
              >
                {cycle.has_image ? "Publish" : "Add a logo to publish"}
              </button>
            )}
            {cycle.is_public && (
              <button className="" onClick={makePrivate}>
                Make Private
              </button>
            )}
            <CycleDropdown cycle={cycle} />
          </div>
          <FormField
            label="Title"
            validate={(text) => {
              if (!text) {
                return "A title is required";
              }
              if (text.length < 3) {
                return "Title should be at least 3 characters long";
              }
            }}
            maxLength={64}
            value={cycle.short_title}
            onChange={handlePropertyChange("short_title")}
            onFinish={() => save({ short_title: cycle.short_title })}
          />
          <FormField
            label="Long Title"
            helperText="This title is used to help with searching, and appears on the page for this prayer cycle"
            maxLength={128}
            validate={(text) => {
              if (!text) {
                return "A title is required";
              }
              if (text.length < 3) {
                return "Title should be at least 3 characters long";
              }
            }}
            value={cycle.long_title}
            onChange={handlePropertyChange("long_title")}
            onFinish={() => save({ long_title: cycle.long_title })}
          />
          <FormField
            label="Description"
            maxLength={255}
            value={cycle.description}
            onChange={handlePropertyChange("description")}
            onFinish={() => save({ description: cycle.description })}
          />
          <FormField
            label="Organisation Name"
            // helperText="Currently unused, but you may want to put one in, just in case"
            maxLength={255}
            value={cycle.organisation_name}
            onChange={handlePropertyChange("organisation_name")}
            onFinish={() =>
              save({ organisation_name: cycle.organisation_name })
            }
          />
          <div className="cols">
            <div className="col">
              <FormField
                label="Start Date"
                type="date"
                value={cycle.start_date}
                onChange={handlePropertyChange("start_date")}
                onFinish={() => save({ start_date: cycle.start_date })}
              />
            </div>
            <div className="col">
              <FormField
                label="End Date"
                type="date"
                value={cycle.end_date}
                onChange={handlePropertyChange("end_date")}
                onFinish={() => save({ end_date: cycle.end_date })}
              />
            </div>
          </div>
        </div>

        {/* <label>Feature Colour</label>
        <input
          type="color"
          value={cycle.feature_color}
          onChange={handlePropertyChange("feature_color")}
        /> */}
        <h2>Prayers</h2>

        {cycle.prayers_json && cycle.prayers_json.length > 0 && (
          <button className="outlined" onClick={addPrayer}>
            Add a prayer
          </button>
        )}
        {typeof cycle.prayers_json === "object" &&
          cycle.prayers_json.map((prayer, i, a) => (
            <div key={i} id={`prayer-${i}`} className="card EditorPage_card">
              <div className="EditorPage_side-buttons left">
                <button
                  className="button-reset"
                  onClick={() => {
                    moveUp(i);
                  }}
                  title="Move prayer to earlier in the cycle"
                >
                  <ChevronLeftIcon
                    className="EditorPage_arrow-fix"
                    style={{
                      opacity: i === 0 ? 0 : 1,
                    }}
                    fill={"var(--highlightColor)"}
                  />
                </button>
                <button
                  className="button-reset"
                  onClick={() => {
                    moveDown(i);
                  }}
                  title="Move prayer to later in the cycle"
                >
                  <ChevronRightIcon
                    className="EditorPage_arrow-fix"
                    style={{
                      opacity: i === cycle.prayers_json.length - 1 ? 0 : 1,
                    }}
                    fill={"var(--highlightColor)"}
                  />
                </button>
              </div>
              <div className="EditorPage_side-buttons right">
                <button
                  className="button-reset"
                  onClick={() => edit(i)}
                  title="Edit this prayer"
                >
                  <EditIcon className="" fill="var(--highlightColor)" />
                </button>
                <DeleteButton onDelete={() => remove(i)} />
              </div>
              <div className="flex-row align-center">
                <div className="highlight flex-row align-center">
                  {/* <CalendarIcon fill="currentColor" width="32" height="32" /> */}
                  <span>
                    {i + 1} / {a.length}
                  </span>
                </div>
              </div>
              <PrayerListParser prayer={prayer} />
            </div>
          ))}
        <button className="outlined" onClick={addPrayer}>
          Add a prayer
        </button>
        {/* <details>
          <summary>JSON Dump</summary>
          <small>
            <pre>
              <code>{JSON.stringify(cycle, null, 2)}</code>
            </pre>
          </small>
        </details> */}
      </div>

      {showDialog && (
        <BaseDialog onDismiss={() => {}}>
          <h2 className="m-0">
            Edit prayer for{" "}
            {formatDate(offsetDate(startDate, currentlyEditingIdx))}
          </h2>
          <PrayerCycleFormEditor
            prayer={cycle.prayers_json[currentlyEditingIdx]}
            onSubmit={(prayer) => {
              handlePrayerChange(prayer);
              close();
            }}
            onDismiss={close}
          />
        </BaseDialog>
      )}

      {showDeleteDialog && (
        <BaseDialog onDismiss={() => setShowDeleteDialog(false)}>
          <h1 className="">
            Are you sure you want to delete this prayer cycle?
          </h1>
          <button onClick={() => setShowDeleteDialog(false)}>Go back</button>
          <button
            onClick={() => {
              deleteCycle();
            }}
            className="danger"
          >
            Delete
          </button>
        </BaseDialog>
      )}

      <Portal>
        {hasUnsavedChanges && (
          <button
            className="EditorPage_save-button solid"
            onClick={saveEverything}
          >
            Save Changes
          </button>
        )}
      </Portal>
    </div>
  );
}

function DeleteButton({ onDelete }) {
  return (
    <Menu>
      <MenuButton className="button-reset">
        <DeleteIcon fill="var(--red)" />
      </MenuButton>
      <MenuPopover className="DeleteButton_popover">
        Are you sure?
        <MenuItems>
          <MenuItem onSelect={() => {}} className="button">
            Cancel
          </MenuItem>
          <MenuItem onSelect={onDelete} className="button destructive">
            Delete
          </MenuItem>
        </MenuItems>
      </MenuPopover>
    </Menu>
  );
}

function CycleDropdown({ cycle }) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [transferTo, setTransferTo] = useState(null);
  const navigate = useNavigate();
  const { toast } = useToasts();

  const deleteCycle = () => {
    if (!cycle || !cycle.prayer_cycle_id) {
      return;
    }
    deletePrayerCycle(cycle.prayer_cycle_id)
      .then((res) => {
        navigate("/prayer-cycle", { replace: true });
      })
      .catch((err) =>
        toast.error(
          <span>
            Sorry, an error occured:{" "}
            <code>{err.message || "no error code"}</code>
          </span>
        )
      );
  };

  return (
    <Menu>
      <MenuButton className="button-reset">
        <MenuIcon width="32" height="32" fill="var(--highlightColor)" />
      </MenuButton>
      <BaseMenuList>
        <BaseMenuItem
          onSelect={() =>
            navigate(`/my-cycles/${cycle.prayer_cycle_id}/editor`)
          }
        >
          Bulk import prayers...
        </BaseMenuItem>
        <BaseMenuItem onSelect={() => setShowTransferDialog(true)}>
          Transfer ownership
        </BaseMenuItem>
        <BaseMenuItem onSelect={() => setShowDeleteDialog(true)}>
          <span className="danger">Delete prayer cycle</span>
        </BaseMenuItem>
      </BaseMenuList>
      {showDeleteDialog && (
        <BaseDialog onDismiss={() => setShowDeleteDialog(false)}>
          <h1 className="">
            Are you sure you want to delete this prayer cycle?
          </h1>
          <button onClick={() => setShowDeleteDialog(false)}>Go back</button>
          <button
            onClick={() => {
              deleteCycle();
            }}
            className="danger"
          >
            Delete
          </button>
        </BaseDialog>
      )}
      {showTransferDialog && (
        <BaseDialog onDismiss={() => setShowTransferDialog(false)}>
          <h1 className="">Transfer ownership of this prayer cycle</h1>
          <SelectPerson
            placeholder="Find a person to transfer to"
            onSelect={setTransferTo}
          />
          {transferTo && (
            <div className="flex-row">
              <p>Transferring to</p>
              <div className="flex-row align-center p-2">
                <ProfilePicture
                  hasImage={transferTo.user_has_image}
                  id={transferTo.user_id}
                  name={transferTo.display_name}
                  color={transferTo.color}
                  size="sm"
                />
                <div className="pl-2">
                  <b>
                    {transferTo.display_name} {transferTo.user_flag_emoji}
                  </b>
                </div>
              </div>
            </div>
          )}
          <button onClick={() => setShowTransferDialog(false)}>Go back</button>
          <button
            onClick={() => {
              transferPrayerCycle(
                cycle.prayer_cycle_id,
                transferTo.user_id
              ).then(() => navigate("/prayer-cycle", { replace: true }));
            }}
            disabled={!transferTo}
            className="solid"
          >
            Transfer
          </button>
        </BaseDialog>
      )}
    </Menu>
  );
}

export default PrayerCycleEditor;
