import { Menu, MenuButton } from "@reach/menu-button";
import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  deletePrayerCalendar,
  publishPrayerCalendar,
  savePrayerCalendar,
  transferPrayerCalendar,
  unpublishPrayerCalendar,
  updateCalendarLogo,
} from "../../api";
import BaseDialog from "../../components/BaseDialog";
import { CalendarPicture } from "../../components/ListPicture";
import Spinner from "../../components/Spinner";
import { ReactComponent as EditIcon } from "../../icons/my_prayer_list.svg";
import { toBase64 } from "../../lib";
import "../PrayerCycleEditor/v2.css";
import { useRef } from "react";
import Upload from "../../components/ChangePictureButton";
import { useTitle } from "../../hooks";
import { useToasts } from "../../contexts/ToastContext";
import { BaseMenuItem, BaseMenuList } from "../../components/BaseMenu";
import { ReactComponent as MenuIcon } from "../../icons/vertical_dots_condensed.svg";
import SelectPerson from "../../components/SelectPerson";
import ProfilePicture from "../../components/ProfilePicture";
import FormField from "../../components/FormField";
import { queryClient } from "../../App";
import { Dropzone } from "../../components/editorStuff";
import {
  useMyCalendarQuery,
  useUpdateCalendarDateMutation,
} from "../../api/calendars";
import DatesList from "./DatesList";
import CalendarOutline from "./CalendarOutline";
import ErrorMessage from "../../components/ErrorMessage";
import { useNavigate } from "react-router-dom-v5-compat";

const BLANK_PRAYER_CALENDAR = {
  prayer_calendar_id: null,
  is_public: false,
  short_title: "",
  long_title: "",
  description: "",
  organisation_name: "",
  start_datetime: "2021-01-01",
  has_image: true,
  logo_url: null,
  calendar_dates: [
    {
      date: "2021-01-01",
      introduction: [
        {
          type: "heading",
          text: "Social Services Awareness: Advocacy",
        },
        {
          type: "paragraph",
          text: "The Anglican Church in Aotearoa, New Zealand and Polynesia is the largest provider of social services, after the government, in Aotearoa New Zealand.",
        },
      ],
      prayer: null,
    },
  ],
};

function MyCalendarPage() {
  const { prayer_calendar_id } = useParams();
  const [calendar, setCalendar] = useState();
  useTitle(calendar?.short_title ? `Editing ${calendar?.short_title}` : "");
  const { toast } = useToasts();
  const {
    data: initialCalendar,
    error,
    status,
  } = useMyCalendarQuery(prayer_calendar_id);
  const { mutate: updateDate } =
    useUpdateCalendarDateMutation(prayer_calendar_id);

  useEffect(() => {
    setCalendar(initialCalendar);
  }, [initialCalendar]);

  const handlePropertyChange = (key) => {
    return function (e) {
      setCalendar((old) => {
        return {
          ...old,
          [key]: e.target.value,
        };
      });
    };
  };

  const logoRef = useRef();
  const [picIsLoading, setPicIsLoading] = useState(false);

  const setFile = async () => {
    const file = logoRef.current.files[0];
    // console.log(file);
    setPicIsLoading(true);
    toBase64(file).then((res) => {
      updateCalendarLogo(calendar.prayer_calendar_id, res).then(() => {
        setPicIsLoading(false);
        setCalendar((c) => ({
          ...c,
          has_image: true,
          version_number: Number(c.version_number || 0) + 1,
        }));
      });
    });
  };
  const handlePic = async (file) => {
    if (file.type !== "image/png" && file.type !== "image/jpeg") return;
    // console.log(file);
    setPicIsLoading(true);
    toBase64(file).then((res) => {
      updateCalendarLogo(calendar.prayer_calendar_id, res)
        .then(() => {
          setPicIsLoading(false);
          setCalendar((c) => ({
            ...c,
            has_image: true,
            version_number: Number(c.version_number || 0) + 1,
          }));
        })
        .catch(() => {
          setPicIsLoading(false);
        });
    });
  };

  const save = (parts) => {
    // console.log("hi");
    if (!calendar || !calendar.prayer_calendar_id) {
      return;
    }
    savePrayerCalendar(calendar.prayer_calendar_id, parts)
      .then((res) => {
        toast.add("Saved!");
        queryClient.removeQueries(
          ["feed", "calendars", calendar.prayer_calendar_id],
          {
            exact: true,
          }
        );
        // setHasUnsavedChanges(false);
      })
      .catch((err) =>
        toast.error(
          <span>
            Sorry, an error occured:{" "}
            <code>{err.message || "no error code"}</code>
          </span>
        )
      );
  };
  const makePublic = () => {
    if (!calendar || !calendar.prayer_calendar_id) {
      return;
    }
    if (!calendar.has_image) {
      return toast.error(
        <span>Sorry, a logo is required to publish your prayer calendar</span>
      );
    }
    publishPrayerCalendar(calendar.prayer_calendar_id)
      .then((res) => {
        toast.add("Done!");
        setCalendar({
          ...calendar,
          is_public: true,
        });
        // setHasUnsavedChanges(false);
      })
      .catch((err) =>
        toast.error(
          <span>
            Sorry, an error occured:{" "}
            <code>{err.message || "no error code"}</code>
          </span>
        )
      );
  };
  const makePrivate = () => {
    if (!calendar || !calendar.prayer_calendar_id) {
      return;
    }
    unpublishPrayerCalendar(calendar.prayer_calendar_id)
      .then((res) => {
        toast.add("Done!");
        setCalendar({
          ...calendar,
          is_public: false,
        });
        // setHasUnsavedChanges(false);
      })
      .catch((err) =>
        toast.error(
          <span>
            Sorry, an error occured:{" "}
            <code>{err.message || "no error code"}</code>
          </span>
        )
      );
  };

  if (status === "error") {
    return (
      <ErrorMessage
        messages={{ 404: "Sorry, we couldn’t find that prayer calendar." }}
        code={error?.message}
      />
    );
  }

  if (!calendar) {
    return <Spinner />;
  }
  return (
    <div className="width-wrapper">
      {!calendar.owner && (
        <Redirect to={`/prayer-calendar/${prayer_calendar_id}`} />
      )}
      <CalendarOutline calendarID={prayer_calendar_id} />
      <div className="pb-4" />
      <div className="card">
        <div className="pb-3">
          <Dropzone className="ProfilePicDropzone" onDrop={handlePic}>
            <CalendarPicture
              hasImage={calendar.has_image}
              id={calendar.prayer_calendar_id}
              size="xl"
              color={calendar.feature_color}
              name={calendar.short_title.trim()}
              version={calendar.image_version}
            />
            {picIsLoading && (
              <div className="ProfilePicLoading">
                <Spinner />
              </div>
            )}
          </Dropzone>
          <div className="pb-3" />
          <Upload
            containerClassName=""
            className="outlined"
            ref={logoRef}
            onChange={setFile}
          >
            <EditIcon className="inline-icon" width="24" height="24" />{" "}
            {calendar.has_image ? "Change Logo" : "Add Logo"}
          </Upload>
        </div>
        <div className="pb-3 flex-row align-center">
          <h1 className="m-0 flex-1">
            {calendar.short_title || "New Prayer Calendar"}{" "}
            {!calendar.is_public && (
              <span style={{ fontWeight: "300" }}>(Draft)</span>
            )}
          </h1>
          {!calendar.is_public && (
            <button
              className={calendar.has_image ? "solid" : "text"}
              // disabled={!calendar.has_image}
              onClick={makePublic}
            >
              {calendar.has_image ? "Publish" : "Add a logo to publish"}
            </button>
          )}
          {calendar.is_public && (
            <button className="" onClick={makePrivate}>
              Make Private
            </button>
          )}
          <CalendarDropdown calendar={calendar} />
        </div>
        <FormField
          label="Title"
          validate={(text) => {
            if (!text) {
              return "A title is required";
            }
            if (text.length < 3) {
              return "Title should be at least 3 characters long";
            }
          }}
          maxLength={64}
          value={calendar.short_title}
          onChange={handlePropertyChange("short_title")}
          onFinish={() => save({ short_title: calendar.short_title })}
        />
        <FormField
          label="Long Title"
          helperText="This title is used to help with searching, and appears on the page for this prayer cycle"
          maxLength={128}
          validate={(text) => {
            if (!text) {
              return "A title is required";
            }
            if (text.length < 3) {
              return "Title should be at least 3 characters long";
            }
          }}
          value={calendar.long_title}
          onChange={handlePropertyChange("long_title")}
          onFinish={() => save({ long_title: calendar.long_title })}
        />
        <FormField
          label="Description"
          maxLength={255}
          value={calendar.description}
          onChange={handlePropertyChange("description")}
          onFinish={() => save({ description: calendar.description })}
        />
        <FormField
          label="Organisation Name"
          // helperText="Currently unused, but you may want to put one in, just in case"
          maxLength={255}
          value={calendar.organisation_name}
          onChange={handlePropertyChange("organisation_name")}
          onFinish={() =>
            save({ organisation_name: calendar.organisation_name })
          }
        />
      </div>
      <DatesList />
    </div>
  );
}

function CalendarDropdown({ calendar }) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [transferTo, setTransferTo] = useState(null);
  const navigate = useNavigate();
  const { toast } = useToasts();

  const deleteCalendar = () => {
    if (!calendar || !calendar.prayer_calendar_id) {
      return;
    }
    deletePrayerCalendar(calendar.prayer_calendar_id)
      .then((res) => {
        navigate("/prayer-calendar", { replace: true });
      })
      .catch((err) =>
        toast.error(
          <span>
            Sorry, an error occured:{" "}
            <code>{err.message || "no error code"}</code>
          </span>
        )
      );
  };

  return (
    <Menu>
      <MenuButton className="button-reset">
        <MenuIcon width="32" height="32" fill="var(--highlightColor)" />
      </MenuButton>
      <BaseMenuList>
        <BaseMenuItem onSelect={() => setShowTransferDialog(true)}>
          Transfer ownership
        </BaseMenuItem>
        <BaseMenuItem onSelect={() => setShowDeleteDialog(true)}>
          <span className="danger">Delete Prayer Calendar</span>
        </BaseMenuItem>
      </BaseMenuList>
      {showDeleteDialog && (
        <BaseDialog onDismiss={() => setShowDeleteDialog(false)}>
          <h1 className="">
            Are you sure you want to delete this Prayer Calendar?
          </h1>
          <button onClick={() => setShowDeleteDialog(false)}>Go back</button>
          <button onClick={deleteCalendar} className="danger">
            Delete
          </button>
        </BaseDialog>
      )}
      {showTransferDialog && (
        <BaseDialog onDismiss={() => setShowTransferDialog(false)}>
          <h1 className="">Transfer ownership of this Prayer Calendar</h1>
          <SelectPerson
            placeholder="Find a person to transfer to"
            onSelect={setTransferTo}
          />
          {transferTo && (
            <div className="flex-row">
              <p>Transferring to</p>
              <div className="flex-row align-center p-2">
                <ProfilePicture
                  hasImage={transferTo.user_has_image}
                  id={transferTo.user_id}
                  name={transferTo.display_name}
                  color={transferTo.color}
                  size="sm"
                />
                <div className="pl-2">
                  <b>
                    {transferTo.display_name} {transferTo.user_flag_emoji}
                  </b>
                </div>
              </div>
            </div>
          )}
          <button onClick={() => setShowTransferDialog(false)}>Go back</button>
          <button
            onClick={() => {
              transferPrayerCalendar(
                calendar.prayer_calendar_id,
                transferTo.user_id
              ).then(() => navigate("/my-calendars", { replace: true }));
            }}
            disabled={!transferTo}
            className="solid"
          >
            Transfer
          </button>
        </BaseDialog>
      )}
    </Menu>
  );
}

export default MyCalendarPage;
