import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAccount } from "../AccountContext";

type ThemeName =
  | "goodnews"
  | "discipler"
  | "serve"
  | "unjust"
  | "creation"
  | "wayfinder"
  | "tuia";

type Context = {
  theme: ThemeName;
  setTheme: (theme: ThemeName) => void;
};

const favicons: { el: HTMLLinkElement; path: (theme: string) => string }[] = [
  {
    el: document.getElementById("favicon") as HTMLLinkElement,
    path: (theme) =>
      `${process.env.PUBLIC_URL}/themed_favicons/${theme}/favicon.ico`,
  },
  {
    el: document.getElementById("favicon-16") as HTMLLinkElement,
    path: (theme) =>
      `${process.env.PUBLIC_URL}/themed_favicons/${theme}/favicon_16x16.png`,
  },
  {
    el: document.getElementById("favicon-32") as HTMLLinkElement,
    path: (theme) =>
      `${process.env.PUBLIC_URL}/themed_favicons/${theme}/favicon_32x32.png`,
  },
  {
    el: document.getElementById("favicon-1024") as HTMLLinkElement,
    path: (theme) =>
      `${process.env.PUBLIC_URL}/themed_favicons/${theme}/favicon_1024x1024.png`,
  },
];

const ThemeContext = createContext<Context>(null!);

function ThemeProvider({ children }: { children: ReactNode }) {
  const [theme, setTheme] = useState<ThemeName>("tuia");
  const { account } = useAccount();

  useEffect(() => {
    const theme = localStorage.getItem("theme") as ThemeName | null;

    setTheme(theme || "tuia");
  }, []);

  useEffect(() => {
    if (account) {
      document.documentElement.dataset.theme = theme;
      localStorage.setItem("theme", theme);
    }
    favicons.forEach((favicon) => {
      favicon.el.href = favicon.path(theme);
    });
  }, [theme]);

  useEffect(() => {
    if (!account) {
      document.documentElement.dataset.theme = "tuia";
    }
    if (account) {
      document.documentElement.dataset.theme = theme;
    }
  }, [account]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

function useTheme() {
  return useContext(ThemeContext);
}

export { ThemeProvider, useTheme };
