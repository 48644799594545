import BulkEditor from "./Editor";

export default function CycleTutorial() {
  return (
    <>
      <h3>Write prayers</h3>
      <p>
        Enter each prayer of the cycle, separating each prayer with a row of 3
        or more equals signs
      </p>
      <BulkEditor
        mode="cycle"
        isExample
        value={`Please pray for Waikato Diocesan School for Girls (Hamilton)
Mary Curran - Principal
Sonja, Aaron & Nicole & their Chaplaincy Team

===

Please pray for Waihi School (Temuka)
Allan Short - Headmaster

===

Please pray for Te Aute College (Hawkes Bay)
Shane Hiha - Principal
Dinah Lambert - Chaplain`}
      />
      <h3>Add styling</h3>
      <ul>
        <li>
          Use <b>#</b> for <b>headings</b>
        </li>
        <li>
          Use <b>-</b> for <b>lists</b>
        </li>
        <li>
          Use <b>^</b> for <b>notes</b>
        </li>
      </ul>
      <BulkEditor
        mode="cycle"
        isExample
        value={`# Please pray for Waikato Diocesan School for Girls (Hamilton)
- Mary Curran - Principal
- Sonja, Aaron & Nicole & their Chaplaincy Team

===

# Please pray for Waihi School (Temuka)
- Allan Short - Headmaster

===

# Please pray for Te Aute College (Hawkes Bay)
- Shane Hiha - Principal
- Dinah Lambert - Chaplain`}
      />
      <h3>Add links</h3>
      <p>
        You can turn any <b>paragraph</b>, <b>list item</b>, or <b>note</b> into
        a link by writing the URL at the end, enclosed in [square brackets]
      </p>
      <BulkEditor
        mode="cycle"
        isExample
        value={`Come join us at Tuia![https://tuia.app]`}
      />
    </>
  );
}
