import { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import {
  cancelPrayerCalendarTransfer,
  createPrayerCalendar,
  receivePrayerCalendar,
  useMyCalendarsQuery,
} from "../../api";
import { queryClient } from "../../App";
import BaseDialog from "../../components/BaseDialog";
import Link from "../../components/Link";
import ListPicture, { CalendarPicture } from "../../components/ListPicture";
import Spinner from "../../components/Spinner";
import { useToasts } from "../../contexts/ToastContext";
import { useTitle } from "../../hooks";

function MyCalendarsPage() {
  const { data: calendars, status } = useMyCalendarsQuery();
  useTitle("My Prayer Cycles");

  const transfers = (calendars || []).filter((c) => c.transfer_in_progress);
  const drafts = (calendars || []).filter(
    (c) => !c.transfer_in_progress && c.is_public === false
  );
  const published = (calendars || []).filter(
    (c) => !c.transfer_in_progress && c.is_public === true
  );
  const [showDialog, setShowDialog] = useState(false);
  return (
    <div className="width-wrapper">
      <div className="flex-row align-center">
        <h1 className="flex-1">My Prayer Calendars</h1>
        <button className="button solid" onClick={() => setShowDialog(true)}>
          New Prayer Calendar
        </button>
      </div>
      {showDialog && (
        <NewCalendarDialog onDismiss={() => setShowDialog(false)} />
      )}
      {status === "loading" && <Spinner />}
      {status === "error" && <p>Sorry, an error occured.</p>}
      {status === "success" && (
        <>
          {transfers && transfers.length > 0 && (
            <>
              <h2>Transfers</h2>
              {transfers.map((cycle) => (
                <NewCalendar key={cycle.prayer_calendar_id} calendar={cycle} />
              ))}
            </>
          )}
          {drafts && drafts.length > 0 && (
            <>
              <h2>Drafts</h2>
              {drafts.map((cycle) => (
                <NewCalendar key={cycle.prayer_calendar_id} calendar={cycle} />
              ))}
            </>
          )}
          {published && published.length > 0 && (
            <>
              <h2>Published Prayer Calendars</h2>
              {published.map((cycle) => (
                <NewCalendar key={cycle.prayer_calendar_id} calendar={cycle} />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
}

function Calendar({ calendar }) {
  return (
    <Link
      to={`/prayer-calendar/${calendar.prayer_calendar_id}`}
      className="card"
    >
      <div className="flex-row align-center">
        <CalendarPicture
          hasImage={calendar.has_image}
          id={calendar.prayer_calendar_id}
          size=""
          color={calendar.feature_color}
          name={calendar.short_title.trim()}
          version={calendar.image_version}
        />
        <div className="pl-3">
          <strong>{calendar.short_title}</strong>
          <p className="m-0">Prayer Calendar</p>
        </div>
      </div>
      <p>{calendar.description}</p>
    </Link>
  );
}

function NewCalendar({ calendar }) {
  const navigate = useNavigate();
  const acceptTransfer = () => {
    receivePrayerCalendar(calendar.prayer_calendar_id, "accept")
      .then(() => {
        navigate(`/my-calendars/${calendar.prayer_calendar_id}`);
        queryClient.invalidateQueries("prayer-calendars");
      })
      .catch(console.warn);
  };
  const declineTransfer = () => {
    receivePrayerCalendar(calendar.prayer_calendar_id, "decline")
      .then(() => {
        queryClient.invalidateQueries("prayer-calendars");
      })
      .catch(console.warn);
  };
  const cancelTransfer = () => {
    cancelPrayerCalendarTransfer(calendar.prayer_calendar_id)
      .then(() => {
        queryClient.invalidateQueries("prayer-calendars");
      })
      .catch(console.warn);
  };
  const Comp = calendar.transfer_in_progress ? "div" : Link;
  return (
    <Comp to={`/my-calendars/${calendar.prayer_calendar_id}`} className="card">
      <Link
        to={`/my-calendars/${calendar.prayer_calendar_id}`}
        className="flex-row align-center pb-2"
      >
        <CalendarPicture
          hasImage={calendar.has_image}
          id={calendar.prayer_calendar_id}
          size=""
          color={calendar.feature_color}
          version={calendar.image_version}
          name={calendar.short_title.trim()}
        />
        <div className="pl-3">
          <strong>{calendar.short_title}</strong>
          {calendar.transfer_in_progress &&
            calendar.transfer_direction === "incoming" && (
              <p className="m-0">
                {calendar.is_public ? "Prayer Calendar" : "Draft"}{" "}
                {calendar.transfer_log && (
                  <span>
                    from{" "}
                    <b>
                      <Link to={`/person/${calendar.author_id}`}>
                        {calendar.author_display_name}
                      </Link>
                    </b>
                  </span>
                )}
              </p>
            )}
          {calendar.transfer_in_progress &&
            calendar.transfer_direction === "outgoing" && (
              <p className="m-0">
                Transferring to{" "}
                <b>
                  <Link to={`/person/${calendar.transfer_to.id}`}>
                    {calendar.transfer_to.display_name}
                  </Link>
                </b>
              </p>
            )}
        </div>
      </Link>
      <p className="m-0">{calendar.description}</p>
      {calendar.transfer_in_progress &&
        calendar.transfer_direction === "incoming" && (
          <>
            <button className="outlined" onClick={acceptTransfer}>
              Accept Prayer Calendar
            </button>
            <button className="danger" onClick={declineTransfer}>
              Decline
            </button>
          </>
        )}
      {calendar.transfer_in_progress &&
        calendar.transfer_direction === "outgoing" && (
          <button className="px-0 m-0" onClick={cancelTransfer}>
            Cancel transfer
          </button>
        )}
    </Comp>
  );
}

function NewCalendarDialog({ onDismiss }) {
  const { toast } = useToasts();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("idle");
  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("loading");
    createPrayerCalendar(name)
      .then((res) => {
        navigate(`/my-calendars/${res.prayer_calendar_id}`);
        setStatus("success");
      })
      .catch((err) => {
        setStatus("idle");
        toast.error(
          <span>
            Sorry, an error occured: <code>{err.message}</code>
          </span>
        );
      });
  };
  return (
    <BaseDialog onDismiss={onDismiss}>
      <h1>Create a new Prayer Calendar</h1>
      {status === "loading" && <Spinner />}
      {status !== "loading" && (
        <form onSubmit={handleSubmit}>
          <label>
            Prayer Calendar Name
            <br />
            <input
              className="text-field"
              name="name"
              maxLength={64}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <button className="solid">Create</button>
        </form>
      )}
    </BaseDialog>
  );
}

export default MyCalendarsPage;
