import * as React from "react";

const ToastContext = React.createContext();

function ToastProvider({ children }) {
  const [toasts, setToasts] = React.useState([]);

  function addToast(newToast) {
    const toast = {
      content: "",
      timer: 3000,
      className: "",
      ...newToast,
      id: Date.now(),
    };
    setToasts((prev) => [...prev, toast]);
  }
  function removeToast(id) {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  }
  const toast = {
    add: (content, timer = 3000, className) =>
      addToast({ content, timer, className }),
    warn: (content, timer = 3000, className) =>
      addToast({ content, timer, className: "warning " + className }),
    error: (content, timer = 3000, className) =>
      addToast({ content, timer, className: "error " + className }),
  };
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { toasts, toast, removeToast };
  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
}

function useToasts() {
  const context = React.useContext(ToastContext);
  return context;
}

export { ToastProvider, useToasts };
