import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import "./styles.css";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

function BaseDialog({ children, minimal, ...props }) {
  return (
    <DialogOverlay
      className={`DialogOverlay ${minimal ? "minimal" : ""}`}
      {...props}
    >
      <DialogContent className={`DialogContent ${minimal ? "minimal" : ""}`}>
        <button className="Dialog_close button-reset" onClick={props.onDismiss}>
          <CloseIcon />
        </button>
        {children}
      </DialogContent>
    </DialogOverlay>
  );
}

export default BaseDialog;
