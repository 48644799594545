import axios from "axios";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { queryClient } from "../App";
import {
  account,
  appToken,
  authData,
  authToken,
  deviceToken,
  setAccount,
  setAuthToken,
} from "./common";

export async function login(username: string, password: string) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("u", username);
  form_data.append("p", password);
  form_data.append("d", deviceToken);
  const { data } = await axios.post(
    `https://accounts.anglicanchurch.nz/api/authenticate.php`,
    form_data
  );
  if (data.result === "success") {
    setAccount(data);
    setAuthToken(data.authToken);
    // authToken = data.authToken;
    localStorage.setItem("authToken", authToken);
    localStorage.setItem("account", JSON.stringify(account));
  } else {
    throw new Error(data.error);
  }
  return data;
}

export async function logout() {
  localStorage.removeItem("authToken");
  localStorage.removeItem("account");
  queryClient.clear();

  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);

  setAccount({});
  setAuthToken(null);
  await axios.post(
    "https://accounts.anglicanchurch.nz/api/logout.php",
    form_data
  );
}

export async function getHomeFeed(page: number) {
  const form_data = authData();
  form_data.append("pg", page.toString());
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_home_feed.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.prayer_threads || [];
}

export async function getExploreFeed(page: number) {
  const form_data = authData();
  form_data.append("pg", page.toString());
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_explore_feed.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.prayer_threads || [];
}
export function useInfiniteHomeFeed() {
  return useInfiniteQuery(
    "home",
    ({ pageParam = 0 }) => getHomeFeed(pageParam + 1),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length) {
          return allPages.length;
        }
        return undefined;
      },
    }
  );
}
export function useInfiniteExploreFeed() {
  return useInfiniteQuery(
    "explore",
    ({ pageParam = 0 }) => getExploreFeed(pageParam + 1),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length) {
          return allPages.length;
        }
        return undefined;
      },
    }
  );
}

export async function getGroups() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  const { data } = await axios.post(
    "https://tuia.app/api/groups/list_groups.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.group_list || [];
}
export function useGroupsQuery() {
  return useQuery(["groups"], getGroups);
}

export async function getGroup(groupId) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("group_id", groupId);
  const { data } = await axios.post(
    "https://tuia.app/api/groups/get_group.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}
export function useGroupQuery(groupId, options) {
  return useQuery(
    ["groups", groupId],
    () => getGroup(groupId),
    options || {
      enabled: !!groupId,
    }
  );
}

export async function getGroupPrayers(groupId, page) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("group_id", groupId);
  form_data.append("pg", page);
  const { data } = await axios.post(
    "https://tuia.app/api/groups/list_group_threads.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.list || [];
}
export function useInfiniteGroupPrayers(groupId) {
  return useInfiniteQuery(
    ["groups", groupId, "threads"],
    ({ pageParam }) => getGroupPrayers(groupId, pageParam + 1),
    {
      enabled: !!groupId,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length) {
          return allPages.length;
        }
        return undefined;
      },
    }
  );
}

function getPersonsActivityRQ(personId) {
  return async function ({ pageParam = 1 }) {
    const form_data = new FormData();
    form_data.append("a", appToken);
    form_data.append("d", deviceToken);
    form_data.append("t", authToken);
    form_data.append("person_id", personId);
    form_data.append("pg", pageParam.toString());
    const { data } = await axios.post(
      "https://tuia.app/api/feed/get_persons_activity.php",
      form_data
    );
    if (data.result !== "success") {
      throw new Error(data.error);
    }
    return data.prayer_threads || [];
  };
}

export function usePersonsActivity(personId) {
  return useInfiniteQuery(
    ["person", personId, "activity"],
    getPersonsActivityRQ(personId),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length) {
          return allPages.length + 1;
        }
        return undefined;
      },
    }
  );
}

export type PrayerCalendarSuggestion = {
  prayer_list_type: "prayer_calendar";
  id: string;
  author: {
    id: number;
    display_name: string;
    full_name: string;
    bio_in_brief: string;
    has_profile_pic: boolean;
    profile_pic_version_number: number;
    locality: string | null;
    flag_emoji: string | null;
    color: string;
    following: boolean;
  };
  created_timestamp: string;
  updated_timestamp: string | null;
  version_number: number;
  is_public: boolean;
  is_finished: boolean;
  allow_replies: boolean;
  short_title: string;
  long_title: string;
  description: string;
  organisation_name: string;
  feature_color: string;
  start_date: string | null;
  end_date: string | null;
  has_image: boolean;
  image_version: true;
};
export type PrayerCycleSuggestion = {
  prayer_list_type: "prayer_cycle";
  id: string;
  author: {
    id: number;
    display_name: string;
    full_name: string;
    bio_in_brief: string;
    has_profile_pic: boolean;
    profile_pic_version_number: number;
    locality: string | null;
    flag_emoji: string | null;
    color: string;
    following: boolean;
  };
  created_timestamp: string;
  updated_timestamp: string | null;
  version_number: number;
  is_public: boolean;
  is_finished: boolean;
  allow_replies: boolean;
  short_title: string;
  long_title: string;
  description: string;
  organisation_name: string;
  feature_color: string;
  start_date: string | null;
  end_date: string | null;
  has_image: boolean;
  image_version: true;
};

export type PersonSuggestion = {
  person: {
    id: string;
    display_name: string;
    full_name: string;
    bio_in_brief: string;
    has_profile_pic: boolean;
    profile_pic_version_number: number | null;
    locality: string | null;
    flag_emoji: string | null;
    color: string | null;
    following: string;
  };
  reason:
    | "popular"
    | "who_your_followers_follow"
    | "people_you_follow_are_following"
    | null;
};

async function suggestLists() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/suggest_lists.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return (data?.suggested_prayer_lists || []) as (
    | PrayerCalendarSuggestion
    | PrayerCycleSuggestion
  )[];
}
export function useListSuggestions() {
  return useQuery(["feed", "lists"], suggestLists, {
    refetchOnWindowFocus: false,
  });
}

async function suggestPeople() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/suggest_people.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return (data?.suggested_people || []) as PersonSuggestion[];
}
export function usePeopleSuggestions() {
  return useQuery(["feed", "people"], suggestPeople, {
    refetchOnWindowFocus: false,
  });
}

export async function getPrayerCycle(id) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_cycle_id", id);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_cycle.php",
    form_data
  );
  if (data.result === "None") {
    throw new Error("404");
  }
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  data.prayer_cycle.prayers_json = data.prayer_cycle.prayers_json || [];
  return data.prayer_cycle || {};
}
export function usePrayerCycleQuery(id) {
  return useQuery(["feed", "cycles", id], () => getPrayerCycle(id));
}

export async function getMyPrayerCycle(id) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_cycle_id", id);
  form_data.append("include_editable", true.toString());
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_cycle.php",
    form_data
  );
  if (data.result === "None") {
    throw new Error("404");
  }
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  data.prayer_cycle.prayers_json = data.prayer_cycle.prayers_json || [];
  return data.prayer_cycle || {};
}
export function useMyPrayerCycleQuery(id) {
  return useQuery(["feed", "cycles", id], () => getMyPrayerCycle(id));
}

export async function getPrayerCalendar(id) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_calendar_id", id);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_calendar.php",
    form_data
  );
  if (data.result === "None") {
    throw new Error("404");
  }
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.prayer_calendar || {};
}
export function usePrayerCalendarQuery(id) {
  return useQuery(["calendars", id], () => getPrayerCalendar(id));
}

export async function searchPeople(query) {
  if (!query) return [];
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("search_query", query);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/search_people.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  const results = data.search_results;
  return typeof results === "object" ? results : [];
}
export function useSearchPeople(query) {
  return useQuery(["search", "people", query], () => searchPeople(query));
}

export async function searchLocality(query) {
  if (!query) return [];
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("search_text", query);
  const { data } = await axios.post(
    "https://tuia.app/api/users/autocomplete_locality.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  const results = data.possible_localities;
  return typeof results === "object" ? results : [];
}

async function getSettings() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  const { data } = await axios.post(
    "https://tuia.app/api/users/get_settings.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.settings;
}
export function useSettingsQuery() {
  return useQuery("settings", getSettings);
}

export async function updateMySettings(name, value) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("setting_name", name);
  form_data.append("setting_value", value);

  const { data } = await axios.post(
    "https://tuia.app/api/users/update_settings.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}
const dailyPrayersSettings = [
  "myPrayers",
  "friendsPrayers",
  "groupPrayers",
  "prayerCycles",
  "prayerCalendars",
  "o",
  "p",
  "n",
  "g",
  "dailyDevotions",
  "forAllTheSaints",
];
export function useUserSettingsMutation() {
  return useMutation<any, any, any, any>(
    ({ name, value }) => updateMySettings(name, value),
    {
      onMutate: ({ name, value }) => {
        queryClient.setQueryData("settings", (prev: any) => {
          if (dailyPrayersSettings.includes(name)) {
            return {
              ...prev,
              dailyPrayers: {
                ...prev.dailyPrayers,
                [name]: value,
              },
            };
          } else {
            return {
              ...prev,
              [name]: value,
            };
          }
        });
      },
    }
  );
}

async function getSupportSettings(personId) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("person_id", personId);
  const { data } = await axios.post(
    "https://tuia.app/api/users/support_get_settings.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}
export function useSupportSettings(personId) {
  return useQuery(["person", personId, "settings"], () =>
    getSupportSettings(personId)
  );
}

async function getReports() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  const { data } = await axios.post(
    "https://tuia.app/api/support/support_get_reports.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.reports;
}
export function useReportsQuery() {
  return useQuery("reports", getReports);
}

export async function getReport(reportId) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("report_id", reportId);
  const { data } = await axios.post(
    "https://tuia.app/api/support/support_get_report.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.reports;
}
export function useReportQuery(reportId) {
  return useQuery(["reports", reportId], () => getReport(reportId));
}

async function getThread(id) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_thread_id", id);
  const { data } = await axios.post(
    "https://tuia.app/api/prayers/get_thread.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.prayer_thread;
}
export function useThreadQuery(threadId, options) {
  return useQuery(["thread", threadId], () => getThread(threadId), {
    enabled: !!threadId,
    ...options,
  });
}

export async function updateSettings(personId, name, value, reportId, appId) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("person_id", personId);
  form_data.append("report_id", reportId);
  form_data.append("setting_name", name);
  form_data.append("setting_value", value);
  appId && form_data.append("app_id", appId);
  const { data } = await axios.post(
    "https://tuia.app/api/users/support_update_settings.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}
export async function shouldBreak() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  const { data } = await axios.post(
    "https://tuia.app/api/asdfghjkl.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}

/**
 *
 * @param {string} personId
 * @param {"may_receive_promotional_email"|"locality_id"|"bio_in_brief"} name
 * @param {any} value
 */
export async function updateProfile(personId, name, value, reportId, modNotes) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("person_id", personId);
  form_data.append("report_id", reportId);
  form_data.append("setting_name", name);
  form_data.append("setting_value", value);
  modNotes && form_data.append("moderator_note", modNotes);
  const { data } = await axios.post(
    "https://tuia.app/api/users/support_update_profile.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}
export async function updateMyProfile(name, value, other = {}) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("setting_name", name);
  form_data.append("setting_value", value);
  for (const key in other) {
    form_data.append(key, other[key]);
  }
  const { data } = await axios.post(
    "https://tuia.app/api/users/update_profile.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}

export function useProfileMutation() {
  return useMutation<any, any, any, any>(
    ({ name, value, other }) => updateMyProfile(name, value, other),
    {
      retry: 1,
      onMutate: ({ name, value, location, other }) => {
        other = other || {};
        if (location) {
          queryClient.setQueryData("profile", (prev: any) => {
            return {
              ...prev,
              account_profile: {
                ...prev.account_profile,
                ...location,
                ...other,
              },
            };
          });
        }
        if (name) {
          queryClient.setQueryData("profile", (prev: any) => {
            return {
              ...prev,
              account_profile: {
                ...prev.account_profile,
                [name]: value,
                ...other,
              },
            };
          });
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries("profile");
      },
    }
  );
}
export async function getProfile() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  const { data } = await axios.post(
    "https://tuia.app/api/users/get_profile.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}

export function useProfileQuery(options) {
  return useQuery(["profile"], () => getProfile(), options);
}

export { appToken, deviceToken, authToken };

export async function updateReport(reportId, name, value) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("report_id", reportId);
  form_data.append("setting_name", name);
  form_data.append("setting_value", value);
  const { data } = await axios.post(
    "https://tuia.app/api/support/support_update_report.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}
/*
support_update_reports

a
d
t
report_id

setting_name

resolved
credible_report
moderator_notes = string[400]

setting_value

*/

export async function supportUpdateThread(threadID, updates) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_thread_id", threadID);
  for (const key in updates) {
    form_data.append(key, updates[key]);
  }
  const { data } = await axios.post(
    "https://tuia.app/api/prayers/support_update_thread.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}

export async function updateThread(threadID, updates) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_thread_id", threadID);
  for (const key in updates) {
    form_data.append(key, updates[key]);
  }
  const { data } = await axios.post(
    "https://tuia.app/api/prayers/update_thread.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}

async function getComments(threadId, page) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_thread_id", threadId);
  form_data.append("page_number", page);
  const { data } = await axios.post(
    "https://tuia.app/api/prayers/get_texts_pages.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.texts || [];
}

function getCommentsRQ(threadId) {
  return async function ({ pageParam = 0 }) {
    return getComments(threadId, pageParam + 1);
  };
}

export function useInfiniteComments(threadId) {
  return useInfiniteQuery(
    ["thread", threadId, "comments"],
    getCommentsRQ(threadId),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length > 4) {
          return allPages.length;
        }
        return undefined;
      },
    }
  );
}

export async function reportText(textId, reportCode) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken || "");
  form_data.append("t", authToken || "");
  form_data.append("prayer_text_id", textId);
  form_data.append("reason_for_report", reportCode);
  const { data } = await axios.post(
    "https://tuia.app/api/prayers/report_text.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}

async function getTerms() {
  const { data } = await axios.get("https://tuia.app/api/users/get_terms.php");
  return data;
}
export function useTermsQuery() {
  return useQuery(["terms"], getTerms);
}

/**
 *
 * @param {string} groupId
 * @param {string} command
 * @param {{[key: string]: string}} options
 * @returns
 */
export async function runGroupFunction(groupId, command, options) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("group_id", groupId);
  form_data.append("command", command);
  for (const key in options) {
    form_data.append(key, options[key]);
  }
  const { data } = await axios.post(
    "https://tuia.app/api/groups/group_functions.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}
export function useGroupFunctionMutation(groupId) {
  return useMutation<any, any, any, any>(
    ({ command, options }) => runGroupFunction(groupId, command, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["groups", groupId], {
          exact: true,
        });
      },
    }
  );
}

export function reportGroup(groupId, reportCode) {
  return runGroupFunction(groupId, "report_group", {
    reason_for_report: reportCode,
  });
}

async function renameGroup(groupId, name) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("group_id", groupId);
  form_data.append("command", "rename_group");
  form_data.append("group_name", name);
  // form_data.append("command", "delete_group");
  const { data } = await axios.post(
    "https://tuia.app/api/groups/update_group.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}
export function useRenameGroupMutation() {
  return useMutation<any, any, any, any>(
    ({ groupId, name }) => renameGroup(groupId, name),
    {
      onSuccess: (_res, { groupId }) => {
        queryClient.invalidateQueries("groups", { exact: true });
        queryClient.invalidateQueries(["groups", groupId], {
          exact: true,
        });
      },
    }
  );
}

async function deleteGroup(groupId) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("group_id", groupId);
  form_data.append("command", "delete_group");
  const { data } = await axios.post(
    "https://tuia.app/api/groups/update_group.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}
export function useDeleteGroupMutation() {
  return useMutation((groupId) => deleteGroup(groupId), {
    onSuccess: () => {
      queryClient.invalidateQueries("groups", { exact: true });
    },
  });
}

export async function reportPerson(personId, reportCode) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("person_id", personId);
  form_data.append("reason_for_report", reportCode);
  const { data } = await axios.post(
    "https://tuia.app/api/users/report_person.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}

async function getMyPrayers() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("q", "all"); //optional "all", "10", "25", if missing defaults to "10"
  const { data } = await axios.post(
    "https://tuia.app/api/prayers/get_my_threads.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.list;
}
export function useMyPrayersQuery() {
  return useQuery("my-prayers", getMyPrayers);
}
async function getUpdateList() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("last_updated_time", "2022-06-23 12:00:22"); //optional "all", "10", "25", if missing defaults to "10"
  const { data } = await axios.post(
    "https://tuia.app/api/prayers/get_update_list.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}
export function useUpdateListQuery() {
  return useQuery("updates", getUpdateList);
}

export function useUpdateThreadMutation(threadId) {
  return useMutation((obj) => updateThread(threadId, obj), {
    onSuccess: () => {
      queryClient.invalidateQueries("my-prayers");
      queryClient.invalidateQueries(["thread", threadId]);
    },
  });
}

async function updateText(textId, newText) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_text_id", textId);
  form_data.append("update", newText);
  const { data } = await axios.post(
    "https://tuia.app/api/prayers/update_text.php",
    form_data
  );
}
export function useUpdateTextMutation() {
  return useMutation<any, any, any, any>(
    ({ textId, text }) => updateText(textId, text),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("my-prayers");
      },
    }
  );
}

async function sharePrayerThread(
  prayerThreadId,
  shareWithId,
  isSharing,
  sharingId
) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_thread_id", prayerThreadId);
  form_data.append("share_with_group_id", shareWithId);
  form_data.append("sharing", isSharing.toString());
  form_data.append("sharing_id", sharingId);

  const { data } = await axios.post(
    "https://tuia.app/api/groups/share_prayer_thread.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}
export function useShareThreadMutation(threadId) {
  return useMutation<any, any, any, any>(
    ({ shareWithId, isSharing, sharingId }) =>
      sharePrayerThread(threadId, shareWithId, isSharing, sharingId),
    {
      onSuccess: (res, { shareWithId, sharingId }) => {
        // queryClient.setQueryData("my-prayers", (old) => {
        //   const threadIdx = old.findIndex(
        //     (t) => t.prayer_thread_id === threadId
        //   );
        //   if (threadIdx === 1) return old;
        //   const next = old.slice();
        //   if (res.sharing) {
        //     next[threadIdx].groups_shared_with.push({
        //       group_id: shareWithId,
        //       sharing_id: sharingId,
        //     });
        //     return next;
        //   } else {
        //     next[threadIdx].groups_shared_with = next[
        //       threadIdx
        //     ].groups_shared_with.filter((g) => g.group_id !== shareWithId);
        //     return next;
        //   }
        // });
        // queryClient.invalidateQueries(["feed"]);
        queryClient.invalidateQueries(["thread", threadId]);
        // queryClient.invalidateQueries(["groups", shareWithId, "threads"]);
      },
    }
  );
}

async function createNewPrayer(text = "", groupIds = []) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_text", text);
  // form_data.append("color_tags", "[]");
  form_data.append("prayer_is_shared", JSON.stringify(groupIds));
  const { data } = await axios.post(
    "https://tuia.app/api/prayers/create_prayer_thread.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.list;
}
export function useNewPrayerMutation() {
  return useMutation<any, any, any, any>(
    ({ text, groups }) => createNewPrayer(text, groups),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("my-prayers");
      },
    }
  );
}

export async function getMyCycles() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_list_of_my_cycles.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.list || [];
}
export function useMyCyclesQuery() {
  return useQuery("prayer-cycles", getMyCycles);
}

export async function getMyCalendars() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_list_of_my_calendars.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.list || [];
}
export function useMyCalendarsQuery() {
  return useQuery("prayer-calendars", getMyCalendars);
}

async function getSavedPrayers(page) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("pg", page);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_saved_prayers.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.prayer_threads || [];
}
export function useInfiniteSavedPrayers() {
  return useInfiniteQuery(
    "saved",
    ({ pageParam }) => getSavedPrayers(pageParam + 1),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length) {
          return allPages.length;
        }
        return undefined;
      },
    }
  );
}

async function reply(threadId, text) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_thread_id", threadId);
  form_data.append("prayer_text", text);

  const { data } = await axios.post(
    "https://tuia.app/api/prayers/create_response.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}
export function useReplyMutation(threadID: string) {
  return useMutation((text) => reply(threadID, text), {
    onSuccess: () => {
      queryClient.setQueryData(["thread", threadID], (prev: any) => {
        if (!prev) return prev;
        return {
          ...prev,
          count_of_responses: Number(prev.count_of_responses) + 1,
        };
      });
      queryClient.invalidateQueries(["thread", threadID, "comments"]);
    },
  });
}

async function deleteReply(textId) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_text_id", textId);
  form_data.append("delete", "true");
  const { data } = await axios.post(
    "https://tuia.app/api/prayers/update_text.php",
    form_data
  );
  return data || {};
}
export function useDeleteReplyMutation(threadId) {
  return useMutation(deleteReply, {
    onSuccess: () => {
      queryClient.invalidateQueries(["thread", threadId, "comments"]);
    },
  });
}

export async function searchForInvites(groupId, query) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("group_id", groupId);
  form_data.append("search_query", query);
  const { data } = await axios.post(
    "https://tuia.app/api/groups/search_for_invite.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  if (typeof data.search_results === "object") {
    return data.search_results;
  }
  return [];
}
export function useSearchForInvites(groupId, query) {
  return useQuery(["search-for-invites", groupId, query], getMyCalendars);
}

async function getPrayerAlerts() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", account.authToken);

  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_alerts.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}
export function usePrayerAlertQuery() {
  return useQuery("alerts", getPrayerAlerts, {
    staleTime: 1000 * 5,
  });
}

export async function inviteToGroup(groupId, personId) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("group_id", groupId);
  form_data.append("person_id", personId);
  const { data } = await axios.post(
    "https://tuia.app/api/groups/invite.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}

async function getInvites() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("q", "10"); // 10
  const { data } = await axios.post(
    "https://tuia.app/api/groups/list_invites.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.invites || [];
}
export function useInvitesQuery() {
  return useQuery(["groups", "invites"], getInvites);
}

async function respondToInvite(groupId, decision) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("group_id", groupId);
  form_data.append("response", decision);
  const { data } = await axios.post(
    "https://tuia.app/api/groups/invite_response.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
}
export function useAcceptInviteMutation() {
  return useMutation<any, any, any, any>(
    (group) => respondToInvite(group.group_id, "accept"),
    {
      onSuccess: (_res, group) => {
        // queryClient.invalidateQueries(["groups"], {exact: true});
        // queryClient.invalidateQueries(["groups", "invites"], {exact: true});
        queryClient.setQueryData(["groups"], (prev: any) => {
          const next = prev.slice();
          next.unshift(group);
          return next;
        });
        queryClient.setQueryData(["groups", "invites"], (prev: any) => {
          let next = prev.slice();
          next = next.filter((invite) => invite.group_id !== group.group_id);
          return next;
        });
      },
    }
  );
}
export function useRejectInviteMutation() {
  return useMutation<any, any, any, any>(
    (group) => respondToInvite(group.group_id, "reject"),
    {
      onSuccess: (_res, group) => {
        queryClient.setQueryData(["groups", "invites"], (prev: any) => {
          let next = prev.slice();
          next = next.filter((invite) => invite.group_id !== group.group_id);
          return next;
        });
      },
    }
  );
}

async function getStats() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  const { data } = await axios.post(
    "https://tuia.app/api/support/support_get_stats.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.statistics || {};
}
export function useStatsQuery() {
  return useQuery("stats", getStats);
}
async function listMods() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  const { data } = await axios.post(
    "https://tuia.app/api/support/support_list_mods.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data?.list || [];
}
export function useModListQuery() {
  return useQuery("mods", listMods);
}
export async function updateWebPerms(personID, permissionLevel, reason) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("person_id", personID);
  form_data.append("permission_level", permissionLevel);
  reason && form_data.append("reason", reason);
  const { data } = await axios.post(
    "https://tuia.app/api/users/update_webapp_perms.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

async function createGroup(groupName) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("group_name", groupName);
  const { data } = await axios.post(
    "https://tuia.app/api/groups/create_group.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}
export function useCreateGroupMutation() {
  return useMutation(createGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(["groups"], { exact: true });
    },
  });
}

export async function incrementGroupViews(viewId) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("view_id", viewId);

  const { data } = await axios.post(
    "https://tuia.app/api/groups/increment_group_views.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

export async function incrementViews(threadId) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_thread_id", threadId);

  const { data } = await axios.post(
    "https://tuia.app/api/feed/increment_views.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

export async function createPrayerCycle(title) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("task", "create");
  form_data.append("short_title", title);

  const { data } = await axios.post(
    "https://tuia.app/api/feed/update_cycle.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

export async function deletePrayerCycle(cycleID) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("task", "delete");
  form_data.append("prayer_cycle_id", cycleID);
  form_data.append("value", "true");

  const { data } = await axios.post(
    "https://tuia.app/api/feed/update_cycle.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

export async function publishPrayerCycle(cycleID) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("task", "publish");
  form_data.append("prayer_cycle_id", cycleID);
  form_data.append("value", "true");

  const { data } = await axios.post(
    "https://tuia.app/api/feed/update_cycle.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

export async function unpublishPrayerCycle(cycleID) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("task", "publish");
  form_data.append("prayer_cycle_id", cycleID);
  form_data.append("value", "false");

  const { data } = await axios.post(
    "https://tuia.app/api/feed/update_cycle.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

export async function transferPrayerCycle(cycleID, newOwnerID) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("task", "transfer");
  form_data.append("prayer_cycle_id", cycleID);
  form_data.append("value", newOwnerID);

  const { data } = await axios.post(
    "https://tuia.app/api/feed/update_cycle.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

export async function savePrayerCycle(cycleID, parts: any = {}) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("task", "save");
  form_data.append("prayer_cycle_id", cycleID);
  for (const key in parts) {
    if (key === "prayers_json") {
      form_data.append("prayers_json", JSON.stringify(parts.prayers_json));
    } else {
      form_data.append(key, parts[key]);
    }
  }

  const { data } = await axios.post(
    "https://tuia.app/api/feed/update_cycle.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

export async function uploadProfilePicture(base64: string) {
  if (base64.length > 10000000) {
    throw Error("toobig");
  }
  if (base64.startsWith("data")) {
    base64 = base64.split(",")[1];
  }
  // console.log("`sendImage`");
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("base64image", base64);
  const { data } = await axios.post(
    "https://tuia.app/api/users/upload_profile_image.php",
    form_data
  );

  if (data.result !== "success") {
    throw new Error(data.error);
  }
  queryClient.invalidateQueries("profile", { exact: true });
  return data || {};
}

export async function updateCycleLogo(cycleID, base64) {
  if (base64.length > 10000000) {
    throw Error("toobig");
  }
  if (base64.startsWith("data")) {
    base64 = base64.split(",")[1];
  }
  // console.log("`sendImage`");
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_cycle_id", cycleID);
  form_data.append("base64image", base64);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/upload_cycle_image.php",
    form_data
  );

  if (data.result !== "success") {
    throw new Error(data.error);
  }
  // queryClient.invalidateQueries("profile", { exact: true });
  return data || {};
}

export async function updateCalendarLogo(cycleID, base64) {
  if (base64.length > 10000000) {
    throw Error("toobig");
  }
  if (base64.startsWith("data")) {
    base64 = base64.split(",")[1];
  }
  // console.log("`sendImage`");
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_calendar_id", cycleID);
  form_data.append("base64image", base64);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/upload_calendar_image.php",
    form_data
  );

  if (data.result !== "success") {
    throw new Error(data.error);
  }
  // queryClient.invalidateQueries("profile", { exact: true });
  return data || {};
}

export async function recievePrayerCycle(cycleID, value) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("task", "receive_transfer");
  form_data.append("prayer_cycle_id", cycleID);
  form_data.append("value", value);

  const { data } = await axios.post(
    "https://tuia.app/api/feed/update_cycle.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

export async function cancelPrayerCycleTransfer(cycleID) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("task", "cancel_transfer");
  form_data.append("prayer_cycle_id", cycleID);

  const { data } = await axios.post(
    "https://tuia.app/api/feed/update_cycle.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

export async function updatePrayerCalendar(calendarID, options = {}) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_calendar_id", calendarID);
  for (const key in options) {
    form_data.append(key, options[key]);
  }

  const { data } = await axios.post(
    "https://tuia.app/api/feed/update_calendar.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}
export async function createPrayerCalendar(name) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("task", "create");
  form_data.append("short_title", name);

  const { data } = await axios.post(
    "https://tuia.app/api/feed/update_calendar.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}
export async function savePrayerCalendar(calendarID, parts = {}) {
  return await updatePrayerCalendar(calendarID, { ...parts, task: "save" });
}
export async function publishPrayerCalendar(calendarID) {
  return await updatePrayerCalendar(calendarID, {
    task: "publish",
    value: "true",
  });
}
export async function unpublishPrayerCalendar(calendarID) {
  return await updatePrayerCalendar(calendarID, {
    task: "publish",
    value: "false",
  });
}
export async function deletePrayerCalendar(calendarID) {
  return await updatePrayerCalendar(calendarID, {
    task: "delete",
    value: "true",
  });
}
export async function transferPrayerCalendar(calendarID, personID) {
  return await updatePrayerCalendar(calendarID, {
    task: "transfer",
    value: personID,
  });
}
export async function receivePrayerCalendar(calendarID, value) {
  return await updatePrayerCalendar(calendarID, {
    task: "receive_transfer",
    value: value,
  });
}
export async function cancelPrayerCalendarTransfer(calendarID) {
  return await updatePrayerCalendar(calendarID, {
    task: "cancel_transfer",
  });
}

export async function changePassword(oldPassword, newPassword) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("old_password", oldPassword);
  form_data.append("new_password", newPassword);

  const { data } = await axios.post(
    "https://accounts.anglicanchurch.nz/api/update_password.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

export async function setThreadFlag(threadID, name, value) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("prayer_thread_id", threadID);
  form_data.append("flag_name", name);
  form_data.append("flag_value", value);

  const { data } = await axios.post(
    "https://tuia.app/api/prayers/thread_flag.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}

export function useSaveThreadMutation(threadID) {
  return useMutation(
    (isSaved) => setThreadFlag(threadID, "is_favourite", isSaved),
    {
      onMutate: (isSaved) => {
        // FEED
        queryClient.setQueryData("feed", (prev: any) => {
          if (!prev) return prev;

          const nextPages = prev.pages.map((page) => {
            return page.map((thread) => {
              if (thread.prayer_thread_id !== threadID) {
                return thread;
              } else {
                return {
                  ...thread,
                  flags: {
                    ...thread.flags,
                    is_favourite: isSaved,
                  },
                };
              }
            });
          });
          return {
            ...prev,
            pages: nextPages,
          };
        });
        // THREAD
        // SAVED
        // GROUP
        // ACTIVITY
        // DAILY PRAYERS?
      },
      onSuccess: () => {
        queryClient.invalidateQueries("saved");
      },
    }
  );
}
export function usePrayThreadMutation(threadID: string) {
  return useMutation(
    (isSaved) => setThreadFlag(threadID, "has_prayed", isSaved),
    {
      onMutate: (isSaved) => {
        // FEED
        queryClient.setQueryData("feed", (prev: any) => {
          if (!prev) return prev;

          const nextPages = prev.pages.map((page) => {
            return page.map((thread) => {
              if (thread.prayer_thread_id !== threadID) {
                return thread;
              } else {
                return {
                  ...thread,
                  flags: {
                    ...thread.flags,
                    has_prayed: isSaved,
                  },
                };
              }
            });
          });
          return {
            ...prev,
            pages: nextPages,
          };
        });
        // THREAD
        // SAVED
        // GROUP
        // ACTIVITY
        // DAILY PRAYERS?
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["thread", threadID]);
      },
    }
  );
}

export async function listMutedPeople() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);

  const { data } = await axios.post(
    "https://tuia.app/api/users/list_muted_people.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data?.muted_people_list || [];
}

export function useMutedPeopleQuery() {
  return useQuery("mutedPeople", listMutedPeople);
}

export async function listBlockedPeople() {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);

  const { data } = await axios.post(
    "https://tuia.app/api/users/list_blocked_people.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data?.blocked_people_list || [];
}

export function useBlockedPeopleQuery() {
  return useQuery("blockedPeople", listBlockedPeople);
}

export async function mutePerson(personID) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("person_id", personID);
  form_data.append("reason_id", "1");

  const { data } = await axios.post(
    "https://tuia.app/api/users/mute_person.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}
export function useMuteMutation(options) {
  return useMutation(mutePerson, options);
}

export async function unmutePerson(personID) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("person_id", personID);
  form_data.append("reason_id", "1");

  const { data } = await axios.post(
    "https://tuia.app/api/users/unmute_person.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}
export function useUnmuteMutation() {
  return useMutation(unmutePerson, {
    onMutate: (personID) => {
      queryClient.setQueryData("mutedPeople", (prev: any) => {
        if (!prev) return prev;

        return prev.map((person) => {
          if (person.person.id === personID) {
            return {
              ...person,
              muted: false,
            };
          } else {
            return person;
          }
        });
      });
    },
  });
}

export async function blockPerson(personID) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("person_id", personID);
  form_data.append("reason_id", "1");

  const { data } = await axios.post(
    "https://tuia.app/api/users/block_person.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}
export function useBlockMutation() {
  return useMutation(blockPerson);
}

export async function unblockPerson(personID) {
  const form_data = new FormData();
  form_data.append("a", appToken);
  form_data.append("d", deviceToken);
  form_data.append("t", authToken);
  form_data.append("person_id", personID);
  form_data.append("reason_id", "1");

  const { data } = await axios.post(
    "https://tuia.app/api/users/unblock_person.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data || {};
}
export function useUnblockMutation() {
  return useMutation(unblockPerson, {
    onMutate: (personID) => {
      queryClient.setQueryData("blockedPeople", (prev: any) => {
        if (!prev) return prev;

        return prev.map((person) => {
          if (person.person.id === personID) {
            return {
              ...person,
              blocked: false,
            };
          } else {
            return person;
          }
        });
      });
    },
  });
}

// async function toggleFollowPerson(personID: string) {
//   const form_data = new FormData();
//   form_data.append("a", appToken);
//   form_data.append("d", deviceToken);
//   form_data.append("t", authToken);
//   form_data.append("sub_to_person_id", personID);
//   const { data } = await axios.post(
//     "https://tuia.app/api/feed/sub_toggle_person.php",
//     form_data
//   );
//   if (data.result !== "success") {
//     throw new Error(data.error);
//   }
//   return data.subscribed as boolean;
// }
// export function useFollowPersonMutation(personID: string) {
//   return useMutation(() => toggleFollowPerson(personID), {
//     onMutate: () => {
//       queryClient.setQueryData(["person", personID], (prev: any) => {
//         if (!prev) return prev;
//         return {
//           ...prev,
//           person: {
//             ...prev.person,
//             following: !prev.person.following,
//           }
//         }
//       })
//     },
//     onSuccess: (following) => {
//       queryClient.setQueryData(["person", personID], (prev: any) => {
//         if (!prev) return prev;
//         return {
//           ...prev,
//           person: {
//             ...prev.person,
//             following,
//           }
//         }
//       })
//     }
//   })
// }
