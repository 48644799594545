import { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import {
  cancelPrayerCycleTransfer,
  createPrayerCycle,
  recievePrayerCycle,
  useMyCyclesQuery,
} from "../../api";
import { queryClient } from "../../App";
import BaseDialog from "../../components/BaseDialog";
import Link from "../../components/Link";
import ListPicture, { CyclePicture } from "../../components/ListPicture";
import Spinner from "../../components/Spinner";
import { useToasts } from "../../contexts/ToastContext";
import { useTitle } from "../../hooks";

function MyCyclesPage() {
  const { data: cycles, status } = useMyCyclesQuery();
  const [showDialog, setShowDialog] = useState(false);
  useTitle("My Prayer Cycles");

  const transfers = (cycles || []).filter(
    (cycle) => cycle.transfer_in_progress
  );
  const drafts = (cycles || []).filter(
    (cycle) => !cycle.transfer_in_progress && cycle.is_public === false
  );
  const published = (cycles || []).filter(
    (cycle) => !cycle.transfer_in_progress && cycle.is_public === true
  );

  return (
    <div className="width-wrapper">
      <div className="flex-row align-center">
        <h1 className="flex-1">My Prayer Cycles</h1>
        <button onClick={() => setShowDialog(true)} className="button solid">
          New Prayer Cycle
        </button>
        {showDialog && (
          <NewCycleDialog onDismiss={() => setShowDialog(false)} />
        )}
      </div>
      {status === "loading" && <Spinner />}
      {status === "error" && <p>Sorry, an error occured.</p>}
      {status === "success" && (
        <>
          {transfers && transfers.length > 0 && (
            <>
              <h2>Transfers</h2>
              {transfers.map((cycle) => (
                <Cycle key={cycle.prayer_cycle_id} cycle={cycle} />
              ))}
            </>
          )}
          {drafts && drafts.length > 0 && (
            <>
              <h2>Drafts</h2>
              {drafts.map((cycle) => (
                <Cycle key={cycle.prayer_cycle_id} cycle={cycle} />
              ))}
            </>
          )}
          {published && published.length > 0 && (
            <>
              <h2>Published Prayer Cycles</h2>
              {published.map((cycle) => (
                <Cycle key={cycle.prayer_cycle_id} cycle={cycle} />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
}

function Cycle({ cycle }) {
  const navigate = useNavigate();
  const acceptTransfer = () => {
    recievePrayerCycle(cycle.prayer_cycle_id, "accept")
      .then(() => {
        navigate(`/my-cycles/${cycle.prayer_cycle_id}`);
        queryClient.invalidateQueries("prayer-cycles");
      })
      .catch(console.warn);
  };
  const declineTransfer = () => {
    recievePrayerCycle(cycle.prayer_cycle_id, "decline")
      .then(() => {
        queryClient.invalidateQueries("prayer-cycles");
      })
      .catch(console.warn);
  };
  const cancelTransfer = () => {
    cancelPrayerCycleTransfer(cycle.prayer_cycle_id)
      .then(() => {
        queryClient.invalidateQueries("prayer-cycles");
      })
      .catch(console.warn);
  };
  const Comp = cycle.transfer_in_progress ? "div" : Link;
  return (
    <Comp to={`/my-cycles/${cycle.prayer_cycle_id}`} className="card">
      <Link
        to={`/my-cycles/${cycle.prayer_cycle_id}`}
        className="flex-row align-center pb-2"
      >
        <CyclePicture
          hasImage={cycle.has_image}
          id={cycle.prayer_cycle_id}
          version={cycle.image_version}
          size=""
          color={cycle.feature_color}
          name={cycle.short_title.trim()}
        />
        <div className="pl-3">
          <strong>{cycle.short_title}</strong>
          {cycle.transfer_in_progress &&
            cycle.transfer_direction === "incoming" && (
              <p className="m-0">
                {cycle.is_public ? "Prayer Cycle" : "Draft"}{" "}
                {cycle.transfer_log && (
                  <span>
                    from{" "}
                    <b>
                      <Link to={`/person/${cycle.author_id}`}>
                        {cycle.author_display_name}
                      </Link>
                    </b>
                  </span>
                )}
              </p>
            )}
          {cycle.transfer_in_progress &&
            cycle.transfer_direction === "outgoing" && (
              <p className="m-0">
                Transferring to{" "}
                <b>
                  <Link to={`/person/${cycle.transfer_to.id}`}>
                    {cycle.transfer_to.display_name}
                  </Link>
                </b>
              </p>
            )}
        </div>
      </Link>
      <p className="m-0">{cycle.description}</p>
      {cycle.transfer_in_progress && cycle.transfer_direction === "incoming" && (
        <>
          <button className="outlined" onClick={acceptTransfer}>
            Accept Prayer Cycle
          </button>
          <button className="danger" onClick={declineTransfer}>
            Decline
          </button>
        </>
      )}
      {cycle.transfer_in_progress && cycle.transfer_direction === "outgoing" && (
        <button className="px-0 m-0" onClick={cancelTransfer}>
          Cancel transfer
        </button>
      )}
    </Comp>
  );
}

function NewCycleDialog({ onDismiss }) {
  const { toast } = useToasts();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("idle");
  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("loading");
    createPrayerCycle(name)
      .then((res) => {
        navigate(`/my-cycles/${res.prayer_cycle_id}`);
        setStatus("success");
      })
      .catch((err) => {
        setStatus("idle");
        toast.error(
          <span>
            Sorry, an error occured: <code>{err.message}</code>
          </span>
        );
      });
  };
  return (
    <BaseDialog onDismiss={onDismiss}>
      <h1>Create a new Prayer Cycle</h1>
      {status === "loading" && <Spinner />}
      {status !== "loading" && (
        <form onSubmit={handleSubmit}>
          <label>
            Prayer Cycle Name
            <br />
            <input
              className="text-field"
              name="name"
              maxLength={64}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <button className="solid">Create</button>
        </form>
      )}
    </BaseDialog>
  );
}

export default MyCyclesPage;
