import * as React from "react";

const AccountContext = React.createContext();

function AccountProvider({ children }) {
  const [account, setAccount] = React.useState(() =>
    JSON.parse(localStorage.getItem("account"))
  );
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { account, setAccount };
  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
}

function useAccount() {
  const context = React.useContext(AccountContext);
  const perms = context.account?.userInfo?.permissionLevel || 0;
  const isUser = !!perms;
  const isEditor = perms >= 40;
  const isStats = perms >= 50;
  const isMod = perms >= 70;
  const isAdmin = perms >= 90;
  return {
    ...context,
    permissionLevel: perms,
    isUser,
    isEditor,
    isStats,
    isMod,
    isAdmin,
  };
}

export { AccountProvider, useAccount };
