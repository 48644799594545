import "./styles.css";

function PrayerListParser({ prayer }) {
  let formattedPrayer = [];
  (prayer || []).forEach((section, idx) => {
    switch (section.type) {
      case "list_item":
        const lastSection = formattedPrayer[formattedPrayer.length - 1];
        if (lastSection && lastSection.type === "list") {
          lastSection.items.push(section);
        } else {
          formattedPrayer.push({ type: "list", items: [section] });
        }
        break;
      default:
        formattedPrayer.push(section);
    }
  });

  return (
    <div className="PrayerList">
      {formattedPrayer.map((section, i) => {
        const hasLink = !!section.url;
        const Inner = hasLink ? "a" : "span";
        let url = section.url;
        if (url && !url.startsWith("http")) {
          url = "https://" + url;
        }
        switch (section.type) {
          case "paragraph":
            return (
              <p key={i}>
                <Inner href={url} target="_blank" rel="noopener noreferrer">
                  {section.text}
                </Inner>
              </p>
            );
          case "heading":
            return <h3 key={i}>{section.text}</h3>;
          case "note":
            return (
              <small key={i}>
                <Inner href={url} target="_blank" rel="noopener noreferrer">
                  {section.text}
                </Inner>
              </small>
            );
          case "list":
            return (
              <ul key={i}>
                {section.items.map((item, idx) => {
                  const hasLink = !!item.url;
                  const Inner = hasLink ? "a" : "span";
                  let url = item.url;
                  if (url && !url.startsWith("http")) {
                    url = "https://" + url;
                  }
                  return (
                    <li key={idx}>
                      <Inner
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.text}
                      </Inner>
                    </li>
                  );
                })}
              </ul>
            );
          default:
            return (
              <pre key={i}>
                <code>{JSON.stringify(section, null, 2)}</code>
              </pre>
            );
        }
      })}
    </div>
  );
}

export default PrayerListParser;
