import { useAccount } from "../../AccountContext";
import { useEffect } from "react";
import { usePrayerAlertQuery } from "../../api";
import { useLocation } from "react-router-dom";
import { NavLink } from "../Link";
import { drawerItems } from "./data";
import { ReactComponent as AdminIcon } from "../../icons/settings.svg";
import { ReactComponent as LoginIcon } from "../../icons/login.svg";
import ProfilePicture from "../ProfilePicture";
import { useSelfQuery } from "../../api/person";

function DrawerItem({ item }) {
  const { account } = useAccount();
  const { data: self } = useSelfQuery();
  const { data } = usePrayerAlertQuery();
  const { pathname } = useLocation();
  let href =
    item.href === "/profile" ? `/person/${account?.userInfo?.id}` : item.href;
  let icon =
    item.href === "/profile" ? (
      <ProfilePicture
        id={account?.userInfo?.id}
        hasImage={self?.person.has_profile_pic}
        color={self?.person.color}
        name={self?.person.display_name}
        size="sm"
        style={{ marginRight: 12 }}
      />
    ) : (
      <item.icon width="32" height="32" fill="currentColor" />
    );
  if (item.show && item.show(account?.userInfo.permissionLevel) === false)
    return null;
  return (
    <NavLink
      to={href}
      tabIndex={pathname.startsWith(href) ? -1 : 0}
      className="Drawer_button"
    >
      {icon}
      {item.title}
      {item.href === "/groups" && data && data.group_notifications > 0 && (
        <div className="Drawer_dot" />
      )}
    </NavLink>
  );
}

export default function Drawer() {
  const { account } = useAccount();

  useEffect(() => {
    document.body.classList.add("drawer-visible");
    return () => {
      document.body.classList.remove("drawer-visible");
    };
  }, []);

  // useInterval(() => {
  //   queryClient.invalidateQueries("alerts");
  // }, 60 * 1000);

  return (
    <div className="Drawer">
      <div>
        {drawerItems.map((category) => (
          <>
            {category.show &&
            category.show(account?.userInfo?.permissionLevel) === false
              ? null
              : category.label && (
                  <p className="Drawer_label">{category.label}</p>
                )}
            {category.items.map((item) => (
              <DrawerItem item={item} />
            ))}
          </>
        ))}
      </div>

      <div>
        <DrawerItem
          item={{
            href: "/settings",
            title: "Settings",
            icon: AdminIcon,
          }}
        />
        <DrawerItem
          item={{
            href: "/logout",
            title: "Log out",
            icon: LoginIcon,
          }}
        />
      </div>
    </div>
  );
}
