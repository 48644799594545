import { useAccount } from "../../AccountContext";
import { useState } from "react";
import { usePrayerAlertQuery } from "../../api";
import { useLocation } from "react-router-dom";
import { NavLink } from "../Link";
import { drawerItems } from "./data";
import { ReactComponent as AdminIcon } from "../../icons/settings.svg";
import { ReactComponent as LoginIcon } from "../../icons/login.svg";
import { ReactComponent as MenuIcon } from "../../icons/menu.svg";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import ProfilePicture from "../ProfilePicture";
import { useSelfQuery } from "../../api/person";

function DrawerItem({ item, onClick }) {
  const { account } = useAccount();
  const { data } = usePrayerAlertQuery();
  const { data: self } = useSelfQuery();
  const { pathname } = useLocation();
  let href =
    item.href === "/profile" ? `/person/${account?.userInfo?.id}` : item.href;
  let icon =
    item.href === "/profile" ? (
      <ProfilePicture
        id={account?.userInfo?.id}
        hasImage={self?.person.has_profile_pic}
        color={self?.person.color}
        name={self?.person.display_name}
        size="sm"
        style={{ marginRight: 12 }}
      />
    ) : (
      <item.icon width="32" height="32" fill="currentColor" />
    );
  if (item.show && item.show(account?.userInfo?.permissionLevel) === false)
    return null;
  return (
    <NavLink
      to={href}
      tabIndex={pathname.startsWith(href) ? -1 : 0}
      className="Drawer_button"
      onClick={onClick}
    >
      {icon}
      {item.title}
      {item.href === "/groups" && data && data.group_notifications > 0 && (
        <div className="Drawer_dot" />
      )}
    </NavLink>
  );
}

export default function ModalDrawer({ onMenuButtonClick, ...props }) {
  const { account } = useAccount();
  const [exiting, setExiting] = useState(false);

  const close = () => {
    setExiting(true);
    setTimeout(onMenuButtonClick, 250);
  };

  // useInterval(() => {
  //   queryClient.invalidateQueries("alerts");
  // }, 60 * 1000);

  return (
    <DialogOverlay
      {...props}
      onDismiss={close}
      className={"ModalDrawer_overlay" + (exiting ? " exiting" : " entering")}
    >
      <DialogContent className="ModalDrawer">
        <div>
          <button className="ModalDrawer_button p-0" onClick={close}>
            <MenuIcon width="32" height="32" />
          </button>
          {drawerItems.map((category) => (
            <>
              {category.show &&
              category.show(account?.userInfo?.permissionLevel) === false
                ? null
                : category.label && (
                    <p className="Drawer_label">{category.label}</p>
                  )}
              {category.items.map((item) => (
                <DrawerItem onClick={close} item={item} />
              ))}
            </>
          ))}
        </div>

        <div>
          <DrawerItem
            item={{
              href: "/settings",
              title: "Settings",
              icon: AdminIcon,
            }}
            onClick={close}
          />
          <DrawerItem
            item={{
              href: "/logout",
              title: "Log out",
              icon: LoginIcon,
            }}
            onClick={close}
          />
        </div>
      </DialogContent>
    </DialogOverlay>
  );
}
