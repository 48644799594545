import { useEffect, useState } from "react";
import "./styles.css";

function FormField({
  label,
  value,
  helperText,
  onChange,
  onFinish,
  showErrors,
  validate,
  ...props
}) {
  const [errorText, setErrorText] = useState("");
  const [touched, setTouched] = useState(false);
  const runValidation = () => {
    const result = validate && validate(value);
    if (typeof result === "string") {
      setErrorText(result);
    } else {
      setErrorText("");
    }
  };
  const handleBlur = () => {
    touched && onFinish && onFinish();
    setTouched(false);
    props.onBlur && props.onBlur();
    runValidation();
  };
  const handleChange = (e) => {
    setTouched(true);
    const assumedText = e.target.value;
    if (errorText) {
      const result = validate && validate(assumedText);
      if (typeof result === "string") {
        setErrorText(result);
      } else {
        setErrorText("");
      }
    }
    onChange && onChange(e);
  };

  useEffect(() => {
    if (showErrors) {
      runValidation();
    }
  }, []);
  return (
    <label className="FormField">
      <div className="FormField_label">{label}</div>
      <input
        {...props}
        className="text-field FormField_input"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <div>
        {errorText ? (
          <div className="danger FormField_error">{errorText}</div>
        ) : (
          <div className="FormField_helper">{helperText}</div>
        )}
      </div>
    </label>
  );
}

export default FormField;
