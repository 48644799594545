import { MenuList, MenuItem, Menu, MenuButton } from "@reach/menu-button";
import { ReactComponent as DotsIcon } from "../../icons/vertical_dots_condensed.svg";
import "./styles.css";

export function BaseMenuList(props) {
  return <MenuList {...props} className="BaseMenuList" />;
}

export function BaseMenuItem(props) {
  return (
    <MenuItem {...props} className={`BaseMenuItem ${props.className || ""}`} />
  );
}

export function BaseMenu(props) {
  <Menu>
    <MenuButton>
      <DotsIcon width="32" height="32" fill="var(--highlightColor)" />
    </MenuButton>
    <BaseMenuList>{props.children}</BaseMenuList>
  </Menu>;
}
