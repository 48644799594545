import axios from "axios";
import {
  Link as RouterLink,
  NavLink as RouterNavLink,
  LinkProps,
  NavLinkProps,
} from "react-router-dom";
import { APP_VERSION } from "../constants";

let latestVersion = APP_VERSION;
let retrievedAt = new Date().getTime();

function isOld() {
  const [currMaj, currMin, currPatch] = APP_VERSION.split(".").map((n) =>
    Number(n)
  );
  const [newMaj, newMin, newPatch] = latestVersion
    .split(".")
    .map((n) => Number(n));

  if (newMaj > currMaj) return true;
  if (newMin > currMin) return true;
  if (newPatch > currPatch) return true;
  return false;
}

async function getLatestVersion() {
  const age = new Date().getTime() - retrievedAt;
  // const TEN_SECONDS = 1000 * 10;
  const FIFTEEN_MINUTES = 1000 * 60 * 15;
  if (age > FIFTEEN_MINUTES) {
    try {
      const { data } = await axios.get(
        process.env.PUBLIC_URL + "/version.json"
      );
      if (data?.v) {
        latestVersion = data.v;
        retrievedAt = new Date().getTime();
      }
    } catch {}
  }
}

function reloadIfOld() {
  if (isOld()) {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }
  getLatestVersion();
}

export default function Link<S = unknown>(
  props: LinkProps<S> & React.RefAttributes<HTMLAnchorElement>
) {
  return (
    <RouterLink
      {...props}
      onClick={(...args) => {
        // reloadIfOld();
        props.onClick && props.onClick(...args);
      }}
    />
  );
}

export function NavLink<S = unknown>(
  props: React.PropsWithoutRef<NavLinkProps<S>> &
    React.RefAttributes<HTMLAnchorElement>
) {
  return (
    <RouterNavLink
      {...props}
      onClick={(...args) => {
        // reloadIfOld();
        props.onClick && props.onClick(...args);
      }}
    />
  );
}
