import { useState } from "react";
import "./styles.css";

const kaomojis = [
  // ":(",
  "🥺",
  "😖",
  "😳",
  "😔",
  "😞",
  "😟",
  "😕",
  "😣",
  "😓",
  "😰",
  "😵‍💫",
];

function randomKaomoji() {
  return kaomojis[Math.floor(Math.random() * kaomojis.length)];
}

function Kaomoji() {
  const [kaomoji, setKaomoji] = useState(randomKaomoji);

  return (
    <h1
      className="ErrorMessage_kaomoji"
      onClick={() => setKaomoji(randomKaomoji)}
    >
      {kaomoji}
    </h1>
  );
}

function ErrorMessage({ messages = {}, titles = {}, code }) {
  const _messages = {
    default:
      "Sorry, an error occured. We assure you the programmer involved will not be getting any christmas presents this year.",
    404: "Sorry, we couldn’t find that.",
    ...messages,
  };
  const _titles = {
    default: "Error!",
    404: "Not Found!",
    ...titles,
  };
  return (
    <main className="width-wrapper ErrorMessage">
      <Kaomoji />
      <h1 className="ErrorMessage_title">{_titles[code] || _titles.default}</h1>
      <p>{_messages[code] || _messages.default}</p>
    </main>
  );
}

export default ErrorMessage;
