// import "./App.css";
import React, { Suspense } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import { CompatRoute, CompatRouter } from "react-router-dom-v5-compat";
import MenuBar from "./components/MenuBar";
import { QueryClient, QueryClientProvider } from "react-query";
import { AccountProvider, useAccount } from "./AccountContext";
import LogoutPage from "./pages/LogoutPage";
import Spinner from "./components/Spinner";
import MyCalendarsPage from "./pages/MyCalendarsPage";
import MyCyclesPage from "./pages/MyCyclesPage";
import MyCyclePage from "./pages/MyCyclePage";
import MyCalendarPage from "./pages/MyCalendarPage";
import { ToastProvider } from "./contexts/ToastContext";
import { Redirect } from "react-router-dom";
import CalendarEditorSimple from "./pages/MyCalendarPage/CalendarEditorSimple";
import BulkCycleEditor from "./pages/PrayerCycleEditor/BulkCycleEditor";
import { ThemeProvider } from "./contexts/ThemeContext";
import ErrorMessage from "./components/ErrorMessage";
import SettingsCopyrightPage from "./pages/SettingsCopyrightPage";
import ResourcesPage from "./pages/ResourcesPage";
// import { ReactQueryDevtools } from "react-query/devtools";

const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const SearchPage = React.lazy(() => import("./pages/SearchPage"));
const HomePage = React.lazy(() => import("./pages/HomePage"));
const PersonPage = React.lazy(() => import("./pages/PersonPage"));
const FeedPage = React.lazy(() => import("./pages/FeedPage"));
const ExplorePage = React.lazy(() => import("./pages/ExplorePage"));
const GroupsPage = React.lazy(() => import("./pages/GroupsPage"));
const GroupPage = React.lazy(() => import("./pages/GroupPage"));
const SavedPage = React.lazy(() => import("./pages/SavedPage"));
const ReportsPage = React.lazy(() => import("./pages/ReportsPage"));
const AdminPage = React.lazy(() => import("./pages/AdminPage"));
const PrayerThreadPage = React.lazy(() => import("./pages/PrayerThreadPage"));
const DevToolsPage = React.lazy(() => import("./pages/DevToolsPage"));
const PrayerCyclePage = React.lazy(() => import("./pages/PrayerCyclePage"));
const MyPrayersPage = React.lazy(() => import("./pages/MyPrayersPage"));
const StatsPage = React.lazy(() => import("./pages/StatsPage"));
const SettingsPage = React.lazy(() => import("./pages/SettingsPage"));
const ArtPage = React.lazy(() => import("./pages/ArtPage"));
const SupportPage = React.lazy(() => import("./pages/SupportPage"));
const SupportAdminPage = React.lazy(() => import("./pages/SupportAdminPage"));
const SupportAdminRequestPage = React.lazy(() =>
  import("./pages/SupportAdminRequestPage")
);
const SettingsSafetyPage = React.lazy(() =>
  import("./pages/SettingsSafetyPage")
);
const SettingsThemePage = React.lazy(() => import("./pages/SettingsThemePage"));
const AboutPage = React.lazy(() => import("./pages/AboutPage"));
const PoliciesPage = React.lazy(() => import("./pages/PoliciesPage"));
const SettingsAccountPage = React.lazy(() =>
  import("./pages/SettingsAccountPage")
);
const PrayerCalendarPage = React.lazy(() =>
  import("./pages/PrayerCalendarPage")
);
const PrayerCycleEditor = React.lazy(() => import("./pages/PrayerCycleEditor"));
const FirehosePage = React.lazy(() => import("./pages/FirehosePage"));
const FirehoseTextsPage = React.lazy(() => import("./pages/FirehoseTextsPage"));
const FirehoseImagesPage = React.lazy(() =>
  import("./pages/FirehoseImagesPage")
);
const FirehoseReportsPage = React.lazy(() =>
  import("./pages/FirehoseReportsPage")
);
const CreateLectionaryPage = React.lazy(() =>
  import("./pages/CreateLectionary")
);
// const ConvertFASPage = React.lazy(() => import("./pages/ConvertFAS"));

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      onError: (err) => {
        if (err?.message == 401) {
          window.location.pathname = `/login`;
        }
      },
    },
  },
});

function ForEditors() {
  const { account } = useAccount();
  if (account?.userInfo?.permissionLevel < 40)
    return <Redirect to="/friends" />;
  return null;
}
function ForStats() {
  const { account } = useAccount();
  if (account?.userInfo?.permissionLevel < 50)
    return <Redirect to="/friends" />;
  return null;
}
function ForMods() {
  const { account } = useAccount();
  if (account?.userInfo?.permissionLevel < 70)
    return <Redirect to="/friends" />;
  return null;
}
function ForAdmins() {
  const { account } = useAccount();
  if (account?.userInfo?.permissionLevel < 90)
    return <Redirect to="/friends" />;
  return null;
}

function App() {
  const { account } = useAccount();
  const location = useLocation();
  return (
    <Switch>
      {/* TODO remove this when it becomes unneccesary */}
      <Route path="/v1">
        <Redirect to={location.pathname.slice(3)} />
      </Route>
      <Route path="/" exact>
        <HomePage />

        {account?.userInfo && <Redirect to={`/shared`} />}
      </Route>
      <Route path="/resources" exact>
        <MenuBar />
        <ResourcesPage />
      </Route>
      <Route path="/support" exact>
        <MenuBar />
        <SupportPage />
      </Route>

      <Route path="/search/:query">
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <SearchPage />
        </Suspense>
      </Route>
      <Route path="/search">
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <SearchPage />
        </Suspense>
      </Route>
      <Route path="/admin" exact>
        <MenuBar />
        <ForStats />
        <Suspense fallback={<Spinner />}>
          <AdminPage />
        </Suspense>
      </Route>
      {process.env.NODE_ENV === "development" && (
        <Route path="/lectionary" exact>
          <MenuBar />
          <Suspense fallback={<Spinner />}>
            <CreateLectionaryPage />
          </Suspense>
        </Route>
      )}
      {/* {process.env.NODE_ENV === "development" && (
                    <Route path="/fas" exact>
                      <MenuBar />
                      <Suspense fallback={<Spinner />}>
                        <ConvertFASPage />
                      </Suspense>
                    </Route>
                  )} */}
      <Route path="/devtools" exact>
        <MenuBar />
        <ForAdmins />
        <Suspense fallback={<Spinner />}>
          <DevToolsPage />
        </Suspense>
      </Route>
      <Route path="/firehose" exact>
        <MenuBar />
        <ForAdmins />
        <Suspense fallback={<Spinner />}>
          <FirehosePage />
        </Suspense>
      </Route>
      <Route path="/firehose/texts" exact>
        <MenuBar />
        <ForAdmins />
        <Suspense fallback={<Spinner />}>
          <FirehoseTextsPage />
        </Suspense>
      </Route>
      <Route path="/firehose/images" exact>
        <MenuBar />
        <ForAdmins />
        <Suspense fallback={<Spinner />}>
          <FirehoseImagesPage />
        </Suspense>
      </Route>
      <Route path="/firehose/reports" exact>
        <MenuBar />
        <ForAdmins />
        <Suspense fallback={<Spinner />}>
          <FirehoseReportsPage />
        </Suspense>
      </Route>
      <Route path="/stats">
        <ForStats />
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <StatsPage />
        </Suspense>
      </Route>
      <Route path="/login" exact>
        <LoginPage />
      </Route>
      <Route path="/logout" exact>
        <LogoutPage />
      </Route>
      <Route path="/shared" exact>
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <FeedPage />
        </Suspense>
      </Route>
      <Route path="/explore" exact>
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <ExplorePage />
        </Suspense>
      </Route>
      <Route path="/my-prayers" exact>
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <MyPrayersPage />
        </Suspense>
      </Route>
      <Route path="/my-calendars" exact>
        <ForEditors />
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <MyCalendarsPage />
        </Suspense>
      </Route>
      <Route path="/my-calendars/new" exact>
        <ForEditors />
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <MyCalendarPage />
        </Suspense>
      </Route>
      <Route path="/prayer/:threadID" exact>
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <PrayerThreadPage />
        </Suspense>
      </Route>
      <Route path="/my-calendars/:prayer_calendar_id" exact>
        <ForEditors />
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <MyCalendarPage />
        </Suspense>
      </Route>
      <Route path="/my-calendars/:prayer_calendar_id/editor">
        <ForEditors />
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <CalendarEditorSimple />
        </Suspense>
      </Route>
      <Route path="/my-cycles" exact>
        <MenuBar />
        <ForEditors />
        <Suspense fallback={<Spinner />}>
          <MyCyclesPage />
        </Suspense>
      </Route>
      <Route path="/my-cycles/new" exact>
        <ForEditors />
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <PrayerCycleEditor />
        </Suspense>
      </Route>
      <Route path="/my-cycles/:prayer_cycle_id" exact>
        <ForEditors />
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <MyCyclePage />
        </Suspense>
      </Route>
      <Route path="/my-cycles/:prayer_cycle_id/editor">
        <ForEditors />
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <BulkCycleEditor />
        </Suspense>
      </Route>
      <Route path="/groups" exact>
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <GroupsPage />
        </Suspense>
      </Route>
      <Route path="/groups/:group_id" exact>
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <GroupPage />
        </Suspense>
      </Route>
      <CompatRoute path="/person/:person_id">
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <PersonPage />
        </Suspense>
      </CompatRoute>
      <Route path="/prayer-calendar/:prayer_calendar_id" exact>
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <PrayerCalendarPage />
        </Suspense>
      </Route>
      <Route path="/prayer-cycle/:prayer_cycle_id" exact>
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <PrayerCyclePage />
        </Suspense>
      </Route>
      <Route path="/saved" exact>
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <SavedPage />
        </Suspense>
      </Route>
      <Route path="/art" exact>
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <ArtPage />
        </Suspense>
      </Route>
      <Route path="/reports">
        <ForMods />
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <ReportsPage />
        </Suspense>
      </Route>
      <Route path="/support-requests">
        <ForMods />
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <SupportAdminPage />
        </Suspense>
      </Route>
      <Route path="/settings" exact>
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <SettingsPage />
        </Suspense>
      </Route>
      <Route path="/settings/account">
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <SettingsAccountPage />
        </Suspense>
      </Route>
      <Route path="/settings/themes">
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <SettingsThemePage />
        </Suspense>
      </Route>
      <Route path="/settings/safety">
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <SettingsSafetyPage />
        </Suspense>
      </Route>
      <Route path="/policies">
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <PoliciesPage />
        </Suspense>
      </Route>
      <Route path="/settings/copyright">
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <SettingsCopyrightPage />
        </Suspense>
      </Route>
      <Route path="/about">
        <MenuBar />
        <Suspense fallback={<Spinner />}>
          <AboutPage />
        </Suspense>
      </Route>
      <Route path="*">
        <MenuBar />
        <ErrorMessage
          titles={{
            default: "Not found!",
          }}
          messages={{
            default:
              "Sorry, we couldn't find what you were looking for. We'll do better next time, promise.",
          }}
        />
      </Route>
    </Switch>
  );
}

function AppProviders({ children }) {
  return (
    <AccountProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ToastProvider>
            <Router>
              <CompatRouter>
                <Suspense fallback={<Spinner />}>{children}</Suspense>
              </CompatRouter>
            </Router>
          </ToastProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </AccountProvider>
  );
}

function FullApp() {
  return (
    <AppProviders>
      <App />
    </AppProviders>
  );
}

export default FullApp;
