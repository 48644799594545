export default function SettingsCopyrightPage() {
  return (
    <div>
      <div className="width-wrapper">
        <div className="pb-5" />
        <div className="card">
          <h1>Copyright</h1>
          <p>
            Tuia is copyright © {new Date().getFullYear()} The Anglican Church
            in Aotearoa, New Zealand and Polynesia.
          </p>
          <p>
            Visit the Anglican Church in Aotearoa, New Zealand and Polynesia
            online at{" "}
            <a
              style={{ color: "var(--positiveColor)" }}
              className="font-medium"
              href="https://anglican.org.nz/"
            >
              anglican.org.nz
            </a>
          </p>
        </div>
        <div className="pb-5" />
      </div>
    </div>
  );
}
