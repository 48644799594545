import React from "react";
import "./styles.css";

function GrowInput({ className, inputClass, style, value, ...props }) {
  return (
    <div className={`GrowInput ${className || ""}`} data-dupe={value}>
      <textarea
        rows="1"
        className={` ${inputClass || ""}`}
        value={value}
        {...props}
      ></textarea>
    </div>
  );
}

const GrowInput2 = React.forwardRef(
  ({ className, inputClass, style, value, ...props }, ref) => {
    return (
      <div className={`GrowInput ${className || ""}`} data-dupe={value}>
        <textarea
          ref={ref}
          rows="1"
          className={` ${inputClass || ""}`}
          value={value}
          {...props}
        ></textarea>
      </div>
    );
  }
);

export default GrowInput2;
