import React from "react";
import "./styles.css";

const Upload = React.forwardRef(
  ({ children, className, containerClassName, style, ...props }, ref) => (
    <label
      className={"Upload " + (containerClassName ? containerClassName : "")}
    >
      <span className={"button " + (className ? className : "")} style={style}>
        {children}
      </span>
      <input
        className="Upload_input hidden"
        type="file"
        accept="image/png, image/jpeg"
        {...props}
        ref={ref}
      />
    </label>
  )
);

export default Upload;
