/**
 *
 * @param {any[]} arr Array to rotate
 * @param {number} count How far to rotate it (the item at index `count` will become index `0`)
 * @returns a clone of the array that has been rotated
 */
export function arrayRotate(arr, count) {
  count = count % arr.length;
  return arr.slice(count, arr.length).concat(arr.slice(0, count));
}

/**
 *
 * @param {any[]} prayers `prayers_json`
 * @param {string} startDateString The starting date as a string, straight from the API
 * @returns {{today: any, upcoming: any[]}}
 */
export function rotatePrayerCycle(prayers, startDateString) {
  if (!prayers) return { today: [], upcoming: [] };
  let startDate = new Date(startDateString);
  startDate.setUTCMinutes(
    startDate.getUTCMinutes() + startDate.getTimezoneOffset()
  ); // Shift date to local time. Does this need to be polyfilled?

  const msDifference = new Date() - startDate;
  const msInDay = 1000 * 60 * 60 * 24;

  let dayDifference = parseInt(msDifference / msInDay);
  dayDifference = dayDifference % prayers?.length;
  let rotatedList = arrayRotate(prayers, dayDifference);
  let todaysPrayer = rotatedList[0];
  rotatedList.shift();
  let upcomingPrayers = rotatedList;

  return { today: todaysPrayer, upcoming: upcomingPrayers };
}

/**
 *
 * @param {any[]} prayers `calendar_dates`
 * @returns {{today?: any, upcoming: any[], historical: any[]}}
 */
export function splitPrayerCalendar(prayers) {
  let today = undefined;
  let upcoming = [];
  let historical = [];

  if (!prayers) return { today, upcoming, historical };

  const now = new Date();
  prayers.forEach((prayer, idx) => {
    const date = new Date(prayer.date);
    date.setUTCMinutes(date.getUTCMinutes() + date.getTimezoneOffset()); // Shift date to local time. Does this need to be polyfilled?

    const msInDay = 1000 * 60 * 60 * 24;
    let dayDifference = parseInt((date - now) / msInDay);

    if (dayDifference === 0) {
      today = prayer;
    } else if (dayDifference > 0) {
      upcoming.push(prayer);
    } else {
      historical.push(prayer);
    }
  });

  return { today, upcoming, historical };
}

export function listStringToDate(dateString) {
  const date = new Date(dateString);
  date.setUTCMinutes(date.getUTCMinutes() + date.getTimezoneOffset()); // Shift date to local time. Does this need to be polyfilled?
  return date;
}

/**
 * Creates a new day, offset from the original by a given number of days
 * @param {Date} date
 * @param {number} days
 * @returns {Date}
 */
export function offsetDate(date, days) {
  const dupe = new Date(date);
  dupe.setDate(dupe.getDate() + days);
  return dupe;
}

/**
 *
 * @param {Date} date date
 * @returns {string}
 */
export function formatDate(date) {
  return date.toDateString();
}

/**
 *
 * @param {string | null | undefined} dateString
 * @returns {Date}
 */
function stringToDateTime(dateString) {
  if (dateString === null || dateString === undefined) return null;

  const UTC = new Date(dateString.trim().replace(" ", "T") + "Z");
  UTC.setMinutes(UTC.getMinutes() + UTC.getTimezoneOffset());

  return UTC;
}

export function howLongAgo(date) {
  function getDateString(date) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var month = months[new Date(date).getMonth()]; //months from 1-12
    var day = new Date(date).getDate();
    const year = new Date(date).getFullYear();
    const showDate = new Date().getFullYear() !== year;
    if (showDate) {
      return day + " " + month + " " + year;
    }
    return day + " " + month;
  }

  if (typeof date === "string") {
    date = stringToDateTime(date);
  }

  const today = new Date();
  const milli = today - date;
  const sec = milli / 1000;
  const min = sec / 60;
  if (min < 1) return "a few moments ago";
  if (min < 60) return parseInt(min) + ` minute${min < 2 ? "" : "s"} ago`;
  const hour = min / 60;
  if (hour < 24) return parseInt(hour) + ` hour${hour < 2 ? "" : "s"} ago`;
  const day = hour / 24;
  if (day < 7) return parseInt(day) + ` day${day < 2 ? "" : "s"} ago`;
  const week = day / 7;
  if (day < 31) return parseInt(week) + ` week${week < 2 ? "" : "s"} ago`;
  return getDateString(date);
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function toISODate(date) {
  const pad = function (num) {
    var norm = Math.floor(Math.abs(num));
    return (norm < 10 ? "0" : "") + norm;
  };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate())
  );
}
