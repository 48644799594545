import { useEffect, useLayoutEffect, useState } from "react";
import { useMyCalendarQuery } from "../../api/calendars";
import "./CalendarOutline.css";

export default function CalendarOutline({ calendarID }) {
  const { data: calendar } = useMyCalendarQuery(calendarID);

  const dateCount = calendar?.calendar_dates?.length || 0;

  const [activeIdx, setActiveIdx] = useState(0);

  useEffect(() => {
    document.body.classList.add("calendar-outline-visible");
    return () => {
      document.body.classList.remove("calendar-outline-visible");
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      let scrollTop = window.scrollY;
      let docHeight = document.body.offsetHeight;
      let winHeight = window.innerHeight;
      let scrollPercent = scrollTop / (docHeight - winHeight);
      let scrollPercentClamped = Math.max(
        0,
        Math.min(scrollPercent, 1 - Number.EPSILON)
      );
      let scrollPercentRounded = Math.floor(scrollPercentClamped * dateCount);
      setActiveIdx(scrollPercentRounded);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dateCount]);

  useLayoutEffect(() => {
    const activeEl = document.getElementById(`date-note-${activeIdx}`);
    activeEl?.scrollIntoView({
      block: "nearest",
      inline: "nearest",
    });
  }, [activeIdx]);

  if (dateCount < 5) {
    return null;
  }

  return (
    <div className="CalendarOutline">
      <div className="CalendarOutline_title">Outline</div>
      <ul className="CalendarOutline_ul">
        {calendar.calendar_dates.map((date, i) => (
          <li id={`date-note-${i}`}>
            <a
              className={
                "CalendarOutline_li " + (activeIdx === i ? "active" : "")
              }
              href={`#date-${date.id}`}
            >
              {new Date(date.date).toLocaleDateString(undefined, {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
