import { useAccount } from "../../AccountContext";
import { useEffect } from "react";
import { useInterval } from "../../hooks";
import { queryClient } from "../../App";
import { ReactComponent as MenuIcon } from "../../icons/menu.svg";

export default function FloatingButton({ onMenuButtonClick }) {
  const { account } = useAccount();

  useEffect(() => {
    document.body.classList.add("button-visible");
    return () => {
      document.body.classList.remove("button-visible");
    };
  }, []);

  useInterval(() => {
    queryClient.invalidateQueries("alerts");
  }, 60 * 1000);

  return (
    <button className="MenuButton p-0" onClick={onMenuButtonClick}>
      <MenuIcon width="32" height="32" />
    </button>
  );
}
