import axios from "axios";
import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { queryClient } from "../App";
import { GetPersonData } from "../types";
import { account, authData } from "./common";

const queryKey = {
  myCalendars: ["calendars"] as const,
  oneCalendar: (calendarID: string) => ["calendars", calendarID] as const,
};

export async function getMyCalendar(id: string) {
  const form_data = authData();
  form_data.append("prayer_calendar_id", id);
  form_data.append("qty", "all");
  form_data.append("include_editable", "true");
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_calendar.php",
    form_data
  );
  if (data.result === "None") {
    throw new Error("404");
  }
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.prayer_calendar || {};
}
export function useMyCalendarQuery(id: string) {
  return useQuery(["calendars", id], () => getMyCalendar(id));
}

type UpdateDateOptions =
  | {
      task: "delete";
      prayer_calendar_id: string;
      prayer_date_id: string;
    }
  | {
      task: "create";
      prayer_calendar_id: string;
      prayer_date: string;
      prayer_introduction: any;
      prayer_text: any;
    }
  | {
      task: "update";
      prayer_calendar_id: string;
      prayer_date_id: string;
      prayer_date: string;
      prayer_introduction: any;
      prayer_text: any;
    };
async function updateCalendarDate(options: UpdateDateOptions) {
  const form_data = authData();
  for (const key in options) {
    form_data.append(
      key,
      typeof options[key] === "object"
        ? JSON.stringify(options[key])
        : options[key]
    );
  }
  const { data } = await axios.post(
    "https://tuia.app/api/feed/update_calendar_date.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data as GetPersonData;
}

export function useUpdateCalendarDateMutation(calendarID: string) {
  return useMutation(updateCalendarDate, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey.oneCalendar(calendarID));
    },
  });
}
