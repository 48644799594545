import { useState } from "react";
import BulkEditor from "../../PrayerCycleEditor/BulkCycleEditor/Editor";

const EXAMPLE_1 = `Please pray for Waikato Diocesan School for Girls (Hamilton)
Mary Curran - Principal
Sonja, Aaron & Nicole & their Chaplaincy Team

===

Please pray for Waihi School (Temuka)
Allan Short - Headmaster

===

Please pray for Te Aute College (Hawkes Bay)
Shane Hiha - Principal
Dinah Lambert - Chaplain`;

const EXAMPLE_2 = `# Please pray for Waikato Diocesan School for Girls (Hamilton)
- Mary Curran - Principal
- Sonja, Aaron & Nicole & their Chaplaincy Team

===

# Please pray for Waihi School (Temuka)
- Allan Short - Headmaster

===

# Please pray for Te Aute College (Hawkes Bay)
- Shane Hiha - Principal
- Dinah Lambert - Chaplain`;

const EXAMPLE_3 = `Come join us at Tuia![https://tuia.app]`;

export default function CalendarTutorial() {
  const [ex1, setEx1] = useState(EXAMPLE_1);
  const [ex2, setEx2] = useState(EXAMPLE_2);
  const [ex3, setEx3] = useState(EXAMPLE_3);
  return (
    <>
      <h3>Write prayers</h3>
      <p>
        Enter each prayer of the calendar, starting each prayer with the date
        you want it to appear
      </p>
      <BulkEditor
        mode="calendar"
        isExample
        value={ex1}
        onChangeValue={setEx1}
      />
      <h3>Add styling</h3>
      <ul>
        <li>
          Use <b>#</b> for <b>headings</b>
        </li>
        <li>
          Use <b>-</b> for <b>lists</b>
        </li>
        <li>
          Use <b>^</b> for <b>notes</b>
        </li>
      </ul>
      <BulkEditor
        mode="calendar"
        isExample
        value={ex2}
        onChangeValue={setEx2}
      />
      <h3>Add links</h3>
      <p>
        You can turn any <b>paragraph</b>, <b>list item</b>, or <b>note</b> into
        a link by writing the URL at the end, enclosed in [square brackets]
      </p>
      <BulkEditor
        mode="calendar"
        isExample
        value={ex3}
        onChangeValue={setEx3}
      />
    </>
  );
}
