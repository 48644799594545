import { useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom-v5-compat";
import { savePrayerCycle, useMyPrayerCycleQuery } from "../../../api";
import { queryClient } from "../../../App";
import Spinner from "../../../components/Spinner";
import { useToasts } from "../../../contexts/ToastContext";
import CycleTutorial from "./CycleTutorial";
import BulkEditor from "./Editor";

export default function BulkCycleEditor() {
  const { prayer_cycle_id } = useParams();
  const { data: cycle } = useMyPrayerCycleQuery(prayer_cycle_id, {
    enabled: !!prayer_cycle_id,
  });
  const { toast } = useToasts();
  const navigate = useNavigate();

  const [plaintext, setPlaintext] = useState("");
  const [prayers, setPrayers] = useState([]);
  const [firstIsHeader, setFirstIsHeader] = useState(false);
  const [replaceOldPrayers, setReplaceOldPrayers] = useState(false);

  const uploadPrayers = () => {
    if (!cycle || !cycle.prayer_cycle_id) {
      return;
    }
    const oldPrayers = cycle.prayers_json || [];
    oldPrayers.push(...prayers);
    savePrayerCycle(cycle.prayer_cycle_id, {
      prayers_json: replaceOldPrayers ? prayers : oldPrayers,
    })
      .then((res) => {
        toast.add("Saved!");
        queryClient.removeQueries(["feed", "cycles", cycle.prayer_cycle_id], {
          exact: true,
        });
        navigate(`/prayer-cycle/${prayer_cycle_id}`);
      })
      .catch((err) =>
        toast.error(
          <span>
            Sorry, an error occured:{" "}
            <code>{err.message || "no error code"}</code>
          </span>
        )
      );
  };

  if (!cycle) {
    return <Spinner />;
  }

  return (
    <div className="width-wrapper-lg">
      <div className="flex-row align-center">
        <h1 className="flex-1">{cycle.short_title}: Bulk Import</h1>

        <button className="solid" onClick={uploadPrayers}>
          Save and Upload
        </button>
      </div>
      <details>
        <summary>
          <h2 style={{ display: "inline" }}>How-to</h2>
        </summary>
        <CycleTutorial />
      </details>
      <details>
        <summary>
          <h2 style={{ display: "inline" }}>Options</h2>
        </summary>
        <ul>
          <li>
            <label>
              <input
                type="checkbox"
                checked={firstIsHeader}
                onChange={(e) => setFirstIsHeader(e.target.checked)}
              />{" "}
              Automatically set the first line of each prayer as a title
            </label>
          </li>
          <li>
            <label>
              <input
                type="checkbox"
                checked={replaceOldPrayers}
                onChange={(e) => setReplaceOldPrayers(e.target.checked)}
              />{" "}
              <span className="danger">
                Delete all existing prayers before import
              </span>
            </label>
          </li>
        </ul>
      </details>
      <BulkEditor
        mode="cycle"
        value={plaintext}
        onChangeValue={setPlaintext}
        onChangePrayers={setPrayers}
        options={{ firstIsHeader }}
      />
    </div>
  );
}
