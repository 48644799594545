import { useAccount } from "../../AccountContext";
import { useEffect } from "react";
import { usePrayerAlertQuery } from "../../api";
import { useLocation } from "react-router-dom";
import { NavLink } from "../Link";
import { drawerItems } from "./data";
import { ReactComponent as AdminIcon } from "../../icons/settings.svg";
import { ReactComponent as LoginIcon } from "../../icons/login.svg";
import ProfilePicture from "../ProfilePicture";
import { useSelfQuery } from "../../api/person";

function RailItem({ item }) {
  const { account } = useAccount();
  const { data } = usePrayerAlertQuery();
  const { data: self } = useSelfQuery();
  const { pathname } = useLocation();
  let href =
    item.href === "/profile" ? `/person/${account.userInfo.id}` : item.href;
  let icon =
    item.href === "/profile" ? (
      <ProfilePicture
        id={account.userInfo.id}
        hasImage={self?.person.has_profile_pic}
        color={self?.person.color}
        name={self?.person.display_name}
        size="xs"
      />
    ) : (
      <item.icon width="32" height="32" fill="currentColor" />
    );
  if (item.show && item.show(account?.userInfo.permissionLevel) === false)
    return null;
  return (
    <NavLink
      to={href}
      tabIndex={pathname.startsWith(href) ? -1 : 0}
      className="Rail_button"
    >
      <div className="Rail_highlight">
        {icon}
        {item.href === "/groups" && data && data.group_notifications > 0 && (
          <div className="Rail_dot" />
        )}
      </div>
      {item.title}
    </NavLink>
  );
}

export default function Rail() {
  const { account } = useAccount();

  useEffect(() => {
    document.body.classList.add("rail-visible");
    return () => {
      document.body.classList.remove("rail-visible");
    };
  }, []);

  // useInterval(() => {
  //   queryClient.invalidateQueries("alerts");
  // }, 60 * 1000);

  return (
    <>
      <div className="Rail">
        <div>
          {drawerItems.map((category) => (
            <>
              {category.show &&
              category.show(account?.userInfo.permissionLevel) ===
                false ? null : (
                <p className="Rail_label">{category.label}</p>
              )}
              {category.items.map((item) => (
                <RailItem item={item} />
              ))}
            </>
          ))}
        </div>

        <div>
          <RailItem
            item={{
              href: "/settings",
              title: "Settings",
              icon: AdminIcon,
            }}
          />
          <RailItem
            item={{
              href: "/logout",
              title: "Log out",
              icon: LoginIcon,
            }}
          />
        </div>
      </div>
    </>
  );
}
