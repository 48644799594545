import { useTitle } from "../../hooks";

const ART = [
  { thumb: "tuia_256.png", full: "tuia.png" },
  { thumb: "goodnews_256.png", full: "goodnews.png" },
  { thumb: "discipler_256.png", full: "discipler.png" },
  { thumb: "serve_256.png", full: "serve.png" },
  { thumb: "unjust_256.png", full: "unjust.png" },
  { thumb: "wayfinder_256.png", full: "wayfinder.png" },
];

export default function ResourcesPage() {
  useTitle("Artwork");

  return (
    <div className="width-wrapper my-8">
      <div className="card">
        <h1 className="text-center m-0">Artwork</h1>
        <p className="text-center">
          Click the images to download the full-sized version
        </p>
        <div className="flex-row flex-wrap justify-center">
          {ART.map((art) => (
            <a
              download={art.full}
              href={`${process.env.PUBLIC_URL}/artwork/${art.full}`}
              style={{
                flex: "1 0 256px",
                height: 256,
              }}
              className="flex justify-center py-4"
            >
              <img
                alt=""
                style={{
                  borderRadius: 4,
                }}
                src={`${process.env.PUBLIC_URL}/artwork/${art.thumb}`}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
