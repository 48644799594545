import { useState } from "react";

export function Dropzone({ children, onDrop, ...props }) {
  const [isHovered, setIsHovered] = useState(false);
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (ev) => {
    ev.preventDefault();
    setIsHovered(false);
    if (ev.dataTransfer.items) {
      var file = ev.dataTransfer.items[0].getAsFile();
      file && onDrop && onDrop(file);
    } else {
      ev?.dataTransfer?.files[0] && onDrop && onDrop(ev.dataTransfer.files[0]);
    }
  };
  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={() => setIsHovered(true)}
      onDragLeave={() => setIsHovered(false)}
      {...props}
      className={`${props.className} ${isHovered ? "hovered" : ""}`}
    >
      {children}
    </div>
  );
}
