import PrayerListParser from "../PrayerListParser";

function PrayerCycle({ prayer, idx, count }) {
  return (
    <div className="card">
      {!!idx && (
        <div className="highlight">
          {idx} / {count}
        </div>
      )}
      <PrayerListParser prayer={prayer} />
    </div>
  );
}

export default PrayerCycle;
