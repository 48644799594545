import axios from "axios";
import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { queryClient } from "../App";
import { GetPersonData } from "../types";
import { account, authData } from "./common";

const personKeys = {
  person: (personID: string) => ["person", personID] as const,
  activity: (personID: string) => ["person", personID, "activity"] as const,
};

async function getPerson(personID: string) {
  const form_data = authData();
  form_data.append("person_id", personID);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_person.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  if (data.person.id === null) {
    throw new Error("404");
  }

  return data as GetPersonData;
}

export function usePersonQuery(
  personID: string,
  options: { enabled?: boolean } = {}
) {
  return useQuery(["person", personID], () => getPerson(personID), options);
}

async function getListsPersonIsAuthor(personID: string) {
  const form_data = authData();
  form_data.append("person_id", personID);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_lists_person_is_author.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }

  return data;
}

export function useAuthoredListsQuery(
  personID: string,
  options: { enabled?: boolean } = {}
) {
  return useQuery(
    ["person", personID, "authoredLists"],
    () => getListsPersonIsAuthor(personID),
    options
  );
}
async function getListsFollowedByPerson(personID: string) {
  const form_data = authData();
  form_data.append("person_id", personID);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_lists_followed_by_person.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }

  return data;
}
export function useFollowingListsQuery(
  personID: string,
  options: { enabled?: boolean } = {}
) {
  return useQuery(
    ["person", personID, "followingLists"],
    () => getListsFollowedByPerson(personID),
    options
  );
}

async function getPeopleFollowingPerson(personID: string) {
  const form_data = authData();
  form_data.append("person_id", personID);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_people_following_person.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }

  return data;
}
export function usePersonsFollowersQuery(
  personID: string,
  options: { enabled?: boolean } = {}
) {
  return useQuery(
    ["person", personID, "followers"],
    () => getPeopleFollowingPerson(personID),
    options
  );
}

async function getPeopleFollowedByPerson(personID: string) {
  const form_data = authData();
  form_data.append("person_id", personID);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/get_people_followed_by_person.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }

  return data;
}
export function usePersonsFollowingQuery(
  personID: string,
  options: { enabled?: boolean } = {}
) {
  return useQuery(
    ["person", personID, "following"],
    () => getPeopleFollowedByPerson(personID),
    options
  );
}

export function getMe() {
  return getPerson(account?.userInfo?.id);
}
export function useSelfQuery() {
  return usePersonQuery(account?.userInfo?.id);
}

async function toggleFollowPerson(personID: string) {
  const form_data = authData();
  form_data.append("sub_to_person_id", personID);
  const { data } = await axios.post(
    "https://tuia.app/api/feed/sub_toggle_person.php",
    form_data
  );
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.subscribed as boolean;
}
export function useFollowPersonMutation(personID: string) {
  return useMutation(() => toggleFollowPerson(personID), {
    onMutate: () => {
      queryClient.setQueryData(["person", personID], (prev: any) => {
        if (!prev) return prev;
        return {
          ...prev,
          person: {
            ...prev.person,
            following: !prev.person.following,
          },
        };
      });
    },
    onSuccess: (following) => {
      queryClient.setQueryData(["person", personID], (prev: any) => {
        if (!prev) return prev;
        return {
          ...prev,
          person: {
            ...prev.person,
            following,
          },
        };
      });
    },
  });
}
