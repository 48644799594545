import { useMemo } from "react";
import GrowInput2 from "../../../components/GrowInput";
import PrayerCalendar from "../../../components/PrayerCalendar";
import PrayerCycle from "../../../components/PrayerCycle";
import { toISODate } from "../../../lib";
import "./Editor.css";

function plain2JsonCycle(plaintext, options) {
  const opts = options || {};
  const prayers = [];
  const textParts = plaintext.split(/[ ]*[\t\r\n][ ]*/);
  const urlRegex = /\[[\w\d:/.@]+\.[\w\d:/.@]{2,}\]$/;

  const newPrayer = () => {
    prayers.push([]);
  };
  const newBlock = (block) => {
    if (!prayers.length) {
      newPrayer();
    }
    prayers[prayers.length - 1].push(block);
  };

  // main loop
  textParts.forEach((t) => {
    if (!t) {
      return;
    }
    if (/^={3,}$/.test(t)) {
      newPrayer();
      return;
    }
    if (t.startsWith("# ")) {
      newBlock({ type: "heading", text: t.substring(2) });
      return;
    }
    if (t.startsWith("- ")) {
      if (urlRegex.test(t)) {
        const [rawUrl] = t.match(urlRegex);
        let url = rawUrl.substring(1, rawUrl.length - 1);
        if (!url.startsWith("http")) {
          url = "https://" + url;
        }
        newBlock({
          type: "list_item",
          text: t.substring(2, t.length - rawUrl.length),
          url,
        });
        return;
      } else {
        newBlock({ type: "list_item", text: t.substring(2) });
        return;
      }
    }
    if (t.startsWith("^")) {
      if (urlRegex.test(t)) {
        const [rawUrl] = t.match(urlRegex);
        let url = rawUrl.substring(1, rawUrl.length - 1);
        if (!url.startsWith("http")) {
          url = "https://" + url;
        }
        newBlock({
          type: "note",
          text: t.substring(1, t.length - rawUrl.length),
          url,
        });
        return;
      } else {
        newBlock({ type: "note", text: t.substring(1) });
        return;
      }
    }
    if (urlRegex.test(t)) {
      const [rawUrl] = t.match(urlRegex);
      let url = rawUrl.substring(1, rawUrl.length - 1);
      if (!url.startsWith("http")) {
        url = "https://" + url;
      }
      newBlock({
        type: "paragraph",
        text: t.substring(0, t.length - rawUrl.length),
        url,
      });
      return;
    } else {
      newBlock({ type: "paragraph", text: t });
      return;
    }
  });

  let transformedPrayers = prayers;
  if (opts.firstIsHeader) {
    transformedPrayers = transformedPrayers.map((p) =>
      p.map((b, i) => {
        if (i === 0) {
          return {
            ...b,
            type: "heading",
          };
        }
        return b;
      })
    );
  }

  return transformedPrayers;
}

function plain2JsonCalendar(plaintext, options) {
  const opts = options || {};
  const prayers = [];
  const textParts = plaintext.split(/[ ]*[\t\r\n]+[ ]*/);
  const dateRegex = /^(\d{1,2}\/\d{1,2}\/(\d\d\d\d|\d\d)|\d\d\d\d-\d\d-\d\d)/;
  const urlRegex = /\[[\w\d:/.@]+\.[\w\d:/.@]{2,}\]$/;

  const newPrayer = (dateString) => {
    let date = toISODate(new Date(dateString));
    // uh oh it does mm/dd/yy
    if (dateString.includes("/")) {
      let dateParts = dateString.split("/");
      const year =
        dateParts[2].length === 2 ? "20" + dateParts[2] : dateParts[2];
      const month = dateParts[1];
      const day = dateParts[0];
      date = `${year}-${month}-${day}`;
    }
    prayers.push({
      date: date,
      introduction: [],
      prayer: [],
    });
  };
  const newBlock = (block) => {
    if (!prayers.length) {
      newPrayer(toISODate(new Date()));
    }
    prayers[prayers.length - 1].prayer.push(block);
  };
  const shiftToIntro = () => {
    if (!prayers.length) {
      newPrayer(toISODate(new Date()));
    }
    prayers[prayers.length - 1].introduction.push(
      ...prayers[prayers.length - 1].prayer
    );
    prayers[prayers.length - 1].prayer = [];
  };

  // main loop
  textParts.forEach((t) => {
    if (!t) {
      return;
    }
    if (dateRegex.test(t)) {
      const [date] = t.match(dateRegex);
      newPrayer(date);
      const remainder = t.substring(date.length);
      if (remainder) {
        newBlock({ type: "paragraph", text: remainder.trim() });
      }
      return;
    }
    if (/^-{3,}$/.test(t)) {
      shiftToIntro();
      return;
    }
    if (t.startsWith("# ")) {
      newBlock({ type: "heading", text: t.substring(2) });
      return;
    }
    if (t.startsWith("- ")) {
      if (urlRegex.test(t)) {
        const [rawUrl] = t.match(urlRegex);
        let url = rawUrl.substring(1, rawUrl.length - 1);
        if (!url.startsWith("http")) {
          url = "https://" + url;
        }
        newBlock({
          type: "list_item",
          text: t.substring(2, t.length - rawUrl.length),
          url,
        });
        return;
      } else {
        newBlock({ type: "list_item", text: t.substring(2) });
        return;
      }
    }
    if (t.startsWith("^")) {
      if (urlRegex.test(t)) {
        const [rawUrl] = t.match(urlRegex);
        let url = rawUrl.substring(1, rawUrl.length - 1);
        if (!url.startsWith("http")) {
          url = "https://" + url;
        }
        newBlock({
          type: "note",
          text: t.substring(1, t.length - rawUrl.length),
          url,
        });
        return;
      } else {
        newBlock({ type: "note", text: t.substring(1) });
        return;
      }
    }
    if (urlRegex.test(t)) {
      const [rawUrl] = t.match(urlRegex);
      let url = rawUrl.substring(1, rawUrl.length - 1);
      if (!url.startsWith("http")) {
        url = "https://" + url;
      }
      newBlock({
        type: "paragraph",
        text: t.substring(0, t.length - rawUrl.length),
        url,
      });
      return;
    } else {
      newBlock({ type: "paragraph", text: t });
      return;
    }
  });

  let transformedPrayers = prayers;
  if (opts.firstIsHeader) {
    transformedPrayers = transformedPrayers.map((p) => ({
      ...p,
      introduction: p.introduction.map((b, i) => {
        if (i === 0) {
          return {
            ...b,
            type: "heading",
          };
        }
        return b;
      }),
      prayer: p.prayer.map((b, i) => {
        if (i === 0) {
          return {
            ...b,
            type: "heading",
          };
        }
        return b;
      }),
    }));
  }

  return transformedPrayers;
}

export default function BulkEditor({
  mode,
  value,
  onChangeValue,
  onChangePrayers,
  isExample,
  options,
  ...props
}) {
  const prayers = useMemo(() => {
    if (mode === "cycle") {
      return plain2JsonCycle(value, options);
    } else {
      return plain2JsonCalendar(value, options);
    }
  }, [value, mode, options]);

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      var start = e.target.selectionStart;
      var end = e.target.selectionEnd;

      // set textarea value to: text before caret + tab + text after caret
      e.target.value =
        e.target.value.substring(0, start) +
        "\t" +
        e.target.value.substring(end);

      // put caret at right position again
      e.target.selectionStart = e.target.selectionEnd = start + 1;
      onChangeValue && onChangeValue(e.target.value);
    }
  };

  const Comp = mode === "cycle" ? PrayerCycle : PrayerCalendar;

  return (
    <div {...props} className="BulkEditor_grid">
      <div className="BulkEditor_col BulkEditor_edit">
        {!isExample && <h2>Write</h2>}
        <textarea
          placeholder="Write your prayers"
          className="BulkEditor_input"
          // inputClass="BulkEditor_textarea"
          value={value}
          onChange={(e) => {
            onChangeValue && onChangeValue(e.target.value);
            if (mode === "cycle") {
              onChangePrayers &&
                onChangePrayers(plain2JsonCycle(e.target.value, options));
            } else {
              onChangePrayers &&
                onChangePrayers(plain2JsonCalendar(e.target.value, options));
            }
          }}
          onKeyDown={handleKeyDown}
          // disabled={isExample}
        />
      </div>
      {isExample && (
        <div className="BulkEditor_arrow">
          <div>{"→"}</div>
        </div>
      )}
      <div className="BulkEditor_col">
        {!isExample && <h2>Preview</h2>}
        {!prayers.length && <em>No prayers</em>}
        <div className="BulkEditor_preview">
          {prayers.map((p, i, a) => (
            <Comp prayer={p} key={i} idx={i + 1} count={a.length} />
          ))}
        </div>
      </div>
    </div>
  );
}
