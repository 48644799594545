import { appToken, deviceToken, authToken } from "../../api";
import "./styles.css";

function ProfilePicture({
  size,
  id,
  version,
  hasImage,
  name,
  color,
  ...props
}) {
  // 512, 96, 64, 48
  let imageSize = 96;
  switch (size) {
    case "xs":
      imageSize = 48;
      break;
    case "sm":
      imageSize = 48;
      break;
    case "md":
      imageSize = 64;
      break;
    case "lg":
      imageSize = 96;
      break;
    case "xl":
      imageSize = 512;
      break;
    default:
      break;
  }
  return (
    <>
      {hasImage && (
        <img
          alt=""
          width="64"
          height="64"
          {...props}
          className={"ProfilePicture " + (size ? size : "")}
          src={`https://tuia.app/api/users/get_profile_image.php?a=${appToken}&d=${deviceToken}&t=${authToken}&account_id=${id}&image_size=${imageSize}&v=${version}`}
        />
      )}
      {!hasImage && (
        <div
          {...props}
          className={"ProfilePicture " + (size ? size : "")}
          style={{ backgroundColor: color || "#000", ...(props.style || {}) }}
        >
          <span className="ProfilePicture_text">{name && name[0]}</span>
        </div>
      )}
    </>
  );
}

export default ProfilePicture;
