import { appToken, authToken, deviceToken } from "../../api";
import "./styles.css";

function ListPicture({ size, url, color, name }) {
  if (url)
    return (
      <img
        className={"ProfilePicture " + (size ? size : "")}
        alt=""
        width="64"
        height="64"
        src={url}
      />
    );
  return (
    <div
      className={"ProfilePicture " + (size ? size : "")}
      style={{ backgroundColor: color || "#000" }}
    >
      <span className="ProfilePicture_text">{name && name[0]}</span>
    </div>
  );
}

export default ListPicture;

export function CyclePicture({ size, id, version, hasImage, name, color }) {
  // 512, 96, 64, 48
  let imageSize = 96;
  switch (size) {
    case "sm":
      imageSize = 48;
      break;
    case "md":
      imageSize = 64;
      break;
    case "lg":
      imageSize = 96;
      break;
    case "xl":
      imageSize = 512;
      break;
    default:
      break;
  }
  return (
    <>
      {hasImage && (
        <img
          className={"ProfilePicture " + (size ? size : "")}
          alt=""
          width="64"
          height="64"
          src={`https://tuia.app/api/feed/get_cycle_image.php?a=${appToken}&d=${deviceToken}&t=${authToken}&prayer_cycle_id=${id}&image_size=${imageSize}&v=${version}`}
        />
      )}
      {!hasImage && (
        <div
          className={"ProfilePicture " + (size ? size : "")}
          style={{ backgroundColor: color || "#000" }}
        >
          <span className="ProfilePicture_text">{name && name[0]}</span>
        </div>
      )}
    </>
  );
}

export function CalendarPicture({ size, id, version, hasImage, name, color }) {
  // 512, 96, 64, 48
  let imageSize = 96;
  switch (size) {
    case "sm":
      imageSize = 48;
      break;
    case "md":
      imageSize = 64;
      break;
    case "lg":
      imageSize = 96;
      break;
    case "xl":
      imageSize = 512;
      break;
    default:
      break;
  }
  return (
    <>
      {hasImage && (
        <img
          className={"ProfilePicture " + (size ? size : "")}
          alt=""
          width="64"
          height="64"
          src={`https://tuia.app/api/feed/get_calendar_image.php?a=${appToken}&d=${deviceToken}&t=${authToken}&prayer_calendar_id=${id}&image_size=${imageSize}&v=${version}`}
        />
      )}
      {!hasImage && (
        <div
          className={"ProfilePicture " + (size ? size : "")}
          style={{ backgroundColor: color || "#000" }}
        >
          <span className="ProfilePicture_text">{name && name[0]}</span>
        </div>
      )}
    </>
  );
}
