import "./styles.css";
import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";
import PrayerListParser from "../PrayerListParser";

function PrayerCalendar({ prayer }) {
  let introduction = prayer.introduction;
  let bottomPrayer = prayer.prayer;
  // pull list items together into one list

  return (
    <div className="card p-0">
      {introduction && (
        <div className="PrayerCalendar_section">
          <div className="PrayerCalendar_date flex-row align-center">
            <CalendarIcon fill="currentColor" width="32" height="32" />
            <span>
              {new Date(prayer.date).toLocaleDateString("en-NZ", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </span>
          </div>
          <PrayerListParser prayer={introduction} />
        </div>
      )}
      {bottomPrayer && (
        <div className="PrayerCalendar_section">
          <PrayerListParser prayer={bottomPrayer} />
        </div>
      )}
    </div>
  );
}

export default PrayerCalendar;
