import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { debounce } from "debounce";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { searchPeople } from "../../api";
import ProfilePicture from "../../components/ProfilePicture";
import "./styles.css";

function SelectPerson({ onSelect, ...props }) {
  const [people, setPeople] = useState();

  const search = (query) => {
    searchPeople(query)
      .then((r) => setPeople(r))
      .catch(console.warn);
  };

  const debouncedSearch = useCallback(
    debounce((q) => {
      search(q);
    }, 500),
    []
  );

  return (
    <Combobox>
      <ComboboxInput
        {...props}
        className={
          "text-field d-block w-100 " + (props.className ? props.className : "")
        }
        onChange={(e) => debouncedSearch(e.target.value)}
      />
      <ComboboxPopover>
        <ComboboxList>
          {people?.map((person, i) => (
            <ComboboxOption
              onClick={() => onSelect && onSelect(person)}
              value={person.display_name}
              className="SelectPerson_item"
              key={i}
            >
              <div class="flex-row align-center p-2">
                <ProfilePicture
                  hasImage={person.user_has_image}
                  id={person.user_id}
                  name={person.display_name}
                  color={person.color}
                />
                <div class="pl-3">
                  <b>
                    {person.display_name} {person.user_flag_emoji}
                  </b>
                  <br />
                  <em>{person.full_name}</em>
                </div>
              </div>
            </ComboboxOption>
          ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
}

export default SelectPerson;
