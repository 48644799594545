import { QueryClient, setLogger } from "react-query";

export const appToken = "8G36qCpF";
export let deviceToken = localStorage.getItem("deviceToken");
export let authToken = localStorage.getItem("authToken");
export let account = JSON.parse(localStorage.getItem("account"));

if (deviceToken === null) {
  deviceToken = generateDeviceToken();
  localStorage.setItem("deviceToken", deviceToken);
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      // cacheTime: Infinity,
      // refetchOnWindowFocus: false,
    },
    mutations: {},
  },
});

setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
});

export function authData() {
  const data = new FormData();
  data.append("a", appToken);
  data.append("d", deviceToken);
  data.append("t", authToken);
  return data;
}

export function setAuthToken(token: string) {
  authToken = token;
}
export function setAccount(acc: any) {
  account = acc;
}

function generateDeviceToken() {
  let deviceToken = "";
  let chars = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
    "z",
  ];
  deviceToken = Date.now() + "_";
  let i = 0;
  for (i = 0; i < 16; i++) {
    deviceToken += chars[Math.floor(Math.random() * 60)];
  }
  return deviceToken;
}
