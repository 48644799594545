import { useState } from "react";
import { useParams } from "react-router";
import { usePrayerCalendarQuery } from "../../../api";
import Spinner from "../../../components/Spinner";
import BulkEditor from "../../PrayerCycleEditor/BulkCycleEditor/Editor";
import CalendarTutorial from "./CalendarTutorial";

export default function BulkCalendarEditor() {
  const { prayer_calendar_id } = useParams();
  const { data: calendar } = usePrayerCalendarQuery(prayer_calendar_id, {
    enabled: !!prayer_calendar_id,
  });

  const [plaintext, setPlaintext] = useState("");
  const [prayers, setPrayers] = useState([]);
  const [firstIsHeader, setFirstIsHeader] = useState(false);

  if (!calendar) {
    return <Spinner />;
  }

  return (
    <div className="width-wrapper-lg">
      <div className="flex-row align-center">
        <h1 className="flex-1">{calendar.short_title}: Bulk Import</h1>

        <button
          className="solid"
          // onClick={uploadPrayers}
        >
          Save and Upload
        </button>
      </div>
      <p>This will overwrite any prayers you currently have, BE WARNED</p>
      <details>
        <summary>
          <h2 style={{ display: "inline" }}>How-to</h2>
        </summary>
        <CalendarTutorial />
      </details>
      <details>
        <summary>
          <h2 style={{ display: "inline" }}>Options</h2>
        </summary>
        <ul>
          <li>
            <input
              type="checkbox"
              checked={firstIsHeader}
              onChange={(e) => setFirstIsHeader(e.target.checked)}
            />{" "}
            Automatically set the first line of each prayer as a title
          </li>
        </ul>
      </details>
      <BulkEditor
        mode="calendar"
        value={plaintext}
        onChangeValue={setPlaintext}
        onChangePrayers={setPrayers}
        options={{ firstIsHeader }}
      />
    </div>
  );
}
