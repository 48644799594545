import { ReactComponent as MyPrayersIcon } from "../../icons/my_prayer_list.svg";
import { ReactComponent as AdminIcon } from "../../icons/settings.svg";
import { ReactComponent as ReportIcon } from "../../icons/report_written.svg";
import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";
import { ReactComponent as CycleIcon } from "../../icons/cycle.svg";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import { ReactComponent as SharedIcon } from "../../icons/shared_thin_v3.svg";
import { ReactComponent as ExploreIcon } from "../../icons/explore_thin_v3.svg";
import { ReactComponent as GroupsIcon } from "../../icons/groups_thin_v3.svg";
import { ReactComponent as StarIcon } from "../../icons/star_sparkle_off.svg";
import { ReactComponent as StatsIcon } from "../../icons/graph.svg";
import { ReactComponent as ArtworkIcon } from "../../icons/artwork.svg";

export const drawerItems = [
  {
    label: "",
    items: [
      {
        href: "/profile",
        title: "You",
        icon: SharedIcon,
      },
    ],
  },
  {
    label: "Community",
    items: [
      {
        href: "/search/",
        title: "Search",
        icon: SearchIcon,
      },
      {
        href: "/shared",
        title: "Shared",
        icon: SharedIcon,
      },
      {
        href: "/explore",
        title: "Explore",
        icon: ExploreIcon,
      },
      {
        href: "/groups",
        title: "Groups",
        icon: GroupsIcon,
      },
      {
        href: "/saved",
        title: "Saved",
        icon: StarIcon,
      },
    ],
  },
  {
    label: "Create",
    items: [
      {
        href: "/my-prayers",
        title: "My Prayers",
        icon: MyPrayersIcon,
      },
      {
        href: "/my-calendars",
        title: "My Prayer Calendars",
        icon: CalendarIcon,
        show: (p) => p >= 40,
      },
      {
        href: "/my-cycles",
        title: "My Prayer Cycles",
        icon: CycleIcon,
        show: (p) => p >= 40,
      },
    ],
  },
  {
    label: "Special",
    show: (p) => p >= 40,
    items: [
      {
        href: "/support-requests",
        title: "Support",
        icon: ReportIcon,
        show: (p) => p >= 90,
      },
      {
        href: "/reports",
        title: "Reports",
        icon: ReportIcon,
        show: (p) => p >= 90,
      },
      {
        href: "/stats",
        title: "Statistics",
        icon: StatsIcon,
        show: (p) => p >= 70 || p === 50,
      },
      {
        href: "/art",
        title: "Artwork",
        icon: ArtworkIcon,
        show: (p) => p >= 40,
      },
      {
        href: "/admin",
        title: "Admin",
        icon: AdminIcon,
        show: (p) => p >= 50,
      },
      {
        href: "/firehose",
        title: "Firehose",
        icon: AdminIcon,
        show: (p) => p >= 90,
      },
      {
        href: "/devtools",
        title: "DevTools",
        icon: AdminIcon,
        show: (p) => p >= 90,
      },
    ],
  },
];
