import { useEffect, useRef, useState } from "react";
import BaseDialog from "../../components/BaseDialog";
import GrowInput from "../../components/GrowInput";
// import { ReactComponent as PencilIcon } from "../../icons/pencil.svg";
import { ReactComponent as LinkIcon } from "../../icons/link.svg";
import "./v2.css";

function LinkButton({ blockState, onUrlChange }) {
  const supportedTypes = ["paragraph", "list_item", "note"];
  const [url, setUrl] = useState(blockState.url ? blockState.url : "");
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen && !url && blockState.text.startsWith("http")) {
      setUrl(blockState.text);
    }
  }, [isOpen]);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const handleSubmit = (e) => {
    let validUrl = url;
    if (!url.startsWith("http")) {
      validUrl = "https://" + url;
    }
    e.preventDefault();
    onUrlChange(validUrl);
    close();
  };
  const remove = () => {
    onUrlChange(null);
    close();
  };
  return (
    <>
      <button className="button-reset EditorBlock_link-button" onClick={open}>
        {supportedTypes.includes(blockState.type) && (
          <LinkIcon
            className={`LinkButton_icon ${blockState.url ? "active" : ""}`}
          />
        )}
      </button>
      {isOpen && (
        <BaseDialog minimal onDismiss={close}>
          <form className="LinkButton_form" onSubmit={handleSubmit}>
            <label className="LinkButton_label">
              {blockState.url ? "Change Link:" : "Add Link:"}
            </label>
            <input
              className="text-field"
              placeholder="https://www.tuia.app"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
            <button>{blockState.url ? "Update" : "Add"}</button>
          </form>
          {blockState.url && <button onClick={remove}>Remove Link</button>}
        </BaseDialog>
      )}
    </>
  );
}

function EditorBlock({ blockState, onChange, onSelect, selected, onKeyDown }) {
  const inputRef = useRef();

  const labels = {
    paragraph: "Paragraph",
    heading: "Heading",
    list_item: "List Item",
    note: "Note",
  };
  const label = labels[blockState.type];

  useEffect(() => {
    if (selected) {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.selectionEnd =
        inputRef.current.value.length;
    }
  }, [selected]);

  const toggleLink = () => {
    if (blockState.url) {
      const newBlockState = blockState;
      delete newBlockState.url;
      onChange(newBlockState);
    } else {
      const newBlockState = blockState;
      newBlockState.url = "https://tuia.app";
      onChange(newBlockState);
    }
  };

  const changeUrl = (url) => {
    if (url) {
      onChange({ ...blockState, url });
    } else {
      const newBlockState = blockState;
      delete newBlockState.url;
      onChange(newBlockState);
    }
  };

  return (
    <div className="EditorBlock">
      {/* <select
        value={blockState.type}
        onChange={(e) => onChange({ ...blockState, type: e.target.value })}
      >
        <option value="paragraph">Paragraph</option>
        <option value="heading">Heading</option>
        <option value="list_item">List</option>
      </select> */}
      <div className="flex-row">
        {blockState.type === "list_item" && (
          <div className="EditorBlock_bullet" />
        )}
        <GrowInput
          className={`EditorBlock_item EditorBlock_${blockState.type} ${
            blockState.url ? "EditorBlock_has-link" : ""
          }`}
          ref={inputRef}
          value={blockState.text}
          onChange={(e) => onChange({ ...blockState, text: e.target.value })}
          onFocus={onSelect}
          onKeyDown={(e) => onKeyDown(e, inputRef.current.selectionStart)}
          placeholder={label}
        />
        <LinkButton blockState={blockState} onUrlChange={changeUrl} />
      </div>
    </div>
  );
}

const BLANK_PRAYER = [{ type: "paragraph", text: "" }];

function PrayerCycleFormEditor({ prayer, onChange, onDismiss, onSubmit }) {
  const [blocks, setLocalBlocks] = useState(
    prayer
      ? prayer.map((sec) => ({
          ...sec,
          text: sec.editable_text || sec.text,
        }))
      : BLANK_PRAYER
  );
  const [selectedIdx, setSelectedIdx] = useState(0);

  const setBlocks = (func) => {
    setLocalBlocks((prev) => {
      const next = func(prev);
      onChange && onChange(next);
      return next;
    });
  };

  function setBlock(idx) {
    return (state) => {
      setBlocks((b) => {
        const newBlocks = b.slice();
        newBlocks[idx] = state;
        return newBlocks;
      });
    };
  }

  const changeType = (type = "paragraph") => {
    setBlock(selectedIdx)({ ...blocks[selectedIdx], type });
  };

  const insertBlock = (type = "paragraph", text = "") => {
    setBlocks((b) => {
      let newBlocks = b.slice();
      newBlocks.splice(selectedIdx + 1, 0, { type, text });
      return newBlocks;
    });
    setSelectedIdx(selectedIdx + 1);
  };

  const removeBlock = (idx) => {
    if (blocks.length === 1) return;
    setBlocks((b) => {
      let newBlocks = b.slice();
      newBlocks.splice(idx, 1);
      return newBlocks;
    });
    setSelectedIdx((i) => {
      if (i > 0) {
        return --i;
      }
      return 0;
    });
  };

  const handleReturn = (block, idx) => {
    switch (block.type) {
      case "paragraph":
        insertBlock("paragraph");
        return;
      case "heading":
        insertBlock("paragraph");
        return;
      case "list_item":
        if (block.text) {
          insertBlock("list_item");
        } else {
          setBlock(idx)({ type: "paragraph", text: "" });
        }
        return;
      case "note":
        insertBlock("paragraph");
        return;
      default:
        insertBlock("paragraph");
    }
  };

  const handleKeyDown = (e, idx) => {
    const block = blocks[idx];
    switch (e.key) {
      case "ArrowUp":
        setSelectedIdx((i) => {
          if (i > 0) {
            return --i;
          }
          return 0;
        });
        break;
      case "ArrowDown":
        setSelectedIdx((i) => {
          if (i < blocks.length - 1) {
            return ++i;
          }
          return blocks.length - 1;
        });
        break;
      case "Backspace":
        if (block.text === "") {
          if (
            block.type !== "list_item" ||
            (block.type === "list_item" &&
              selectedIdx > 0 &&
              blocks[selectedIdx - 1].type === "list_item")
          ) {
            removeBlock(idx);
          } else {
            setBlock(idx)({ type: "paragraph", text: "" });
          }
        } else if (e.target.selectionStart === 0) {
          setBlock(idx)({ ...block, type: "paragraph" });
        }
        break;
      case "Enter":
        e.preventDefault();
        handleReturn(block, idx);
        break;
      case " ":
        if (block.type === "paragraph") {
          if (block.text === "-" || block.text === "*") {
            setBlock(idx)({ type: "list_item", text: "" });
            e.preventDefault();
          }
          if (block.text === "#") {
            setBlock(idx)({ type: "heading", text: "" });
            e.preventDefault();
          }
        }
        break;
      default:
        console.log(e.key);
    }
  };

  return (
    <div>
      <div>
        <span>Format text block: </span>
        <button
          name="paragraph"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => changeType("paragraph")}
        >
          Paragraph
        </button>
        <button
          name="heading"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => changeType("heading")}
        >
          Heading (<kbd>#</kbd>)
        </button>
        <button
          name="list_item"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => changeType("list_item")}
        >
          List (<kbd>-</kbd>)
        </button>
        <button
          name="note"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => changeType("note")}
        >
          Note
        </button>
      </div>
      <div
        style={{
          border: "1px solid var(--backgroundColor)",
          padding: "8px",
          marginBottom: "16px",
          borderRadius: "4px",
        }}
      >
        {blocks.map((block, i) => (
          <EditorBlock
            key={i}
            blockState={block}
            onChange={setBlock(i)}
            selected={selectedIdx === i}
            onSelect={() => setSelectedIdx(i)}
            onKeyDown={(e) => handleKeyDown(e, i)}
          />
        ))}
      </div>
      <button
        className="outlined "
        onClick={onDismiss}
        style={{
          marginRight: 8,
        }}
      >
        Cancel
      </button>
      <button className="solid" onClick={() => onSubmit(blocks)}>
        Save and close
      </button>
    </div>
  );
}

export default PrayerCycleFormEditor;
