import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from "@reach/menu-button";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useMyCalendarQuery,
  useUpdateCalendarDateMutation,
} from "../../api/calendars";
import BaseDialog from "../../components/BaseDialog";
import PrayerListParser from "../../components/PrayerListParser";
import { formatDate } from "../../lib";
import PrayerCycleFormEditor from "../PrayerCycleEditor/v2";
import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";
import { ReactComponent as EditIcon } from "../../icons/my_prayer_list.svg";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";

export default function DatesList() {
  const { prayer_calendar_id } = useParams();
  const { data: calendar } = useMyCalendarQuery(prayer_calendar_id);
  const { mutate: updateDate } =
    useUpdateCalendarDateMutation(prayer_calendar_id);

  if (!calendar) return null;

  const dates = calendar.calendar_dates || [];

  const createDate = () => {
    let prayerDate = new Date().toISOString().slice(0, 10);
    if (dates.length) {
      const lastDate = new Date(dates[dates.length - 1].date);
      lastDate.setDate(lastDate.getDate() + 1);
      prayerDate = lastDate.toISOString().slice(0, 10);
    }
    updateDate({
      task: "create",
      prayer_calendar_id: prayer_calendar_id,
      prayer_date: prayerDate,
      prayer_introduction: [],
      prayer_text: [],
    });
  };

  const deleteDate = (dateID) => {
    updateDate({
      task: "delete",
      prayer_calendar_id: prayer_calendar_id,
      prayer_date_id: dateID,
    });
  };

  const changeDate = (date) => {
    updateDate({
      task: "update",
      prayer_calendar_id: prayer_calendar_id,
      prayer_date_id: date.id,
      prayer_date: date.date,
      prayer_introduction: date.introduction
        ? date.introduction.map((section) => ({
            type: section.type,
            text: section.text,
            url: section.url,
          }))
        : null,
      prayer_text: date.prayer
        ? date.prayer.map((section) => ({
            type: section.type,
            text: section.text,
            url: section.url,
          }))
        : null,
    });
  };

  return (
    <>
      <div className="pb-3">
        <button className="solid" onClick={createDate}>
          Add a date
        </button>
      </div>
      {dates.map((date) => (
        <CalendarDate
          key={date.id}
          date={date}
          onChange={changeDate}
          onDelete={() => deleteDate(date.id)}
        />
      ))}
      <div className="pb-5">
        {dates.length > 0 && (
          <button className="solid" onClick={createDate}>
            Add a date
          </button>
        )}
      </div>
    </>
  );
}

function CalendarDate({ date, onChange, onDelete }) {
  const [showDateEditor, setShowDateEditor] = useState(false);
  const [showIntroEditor, setShowIntroEditor] = useState(false);
  const [showPrayerEditor, setShowPrayerEditor] = useState(false);
  const [newDate, setNewDate] = useState(date.date);
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowDateEditor(false);
    if (newDate !== date.date) {
      onChange({
        ...date,
        date: newDate,
      });
    }
  };
  return (
    <div className="card EditorPage_card" id={`date-${date.id}`}>
      {
        <div className="EditorPage_side-buttons right">
          <DeleteButton onDelete={onDelete} />
        </div>
      }
      <div className="flex-row align-center">
        <div className="PrayerCalendar_date flex-row align-center">
          <CalendarIcon fill="currentColor" width="32" height="32" />
          <span>{formatDate(new Date(date.date))}</span>
          {!showDateEditor && (
            <button
              className="button-reset pl-3"
              onClick={() => setShowDateEditor(true)}
            >
              <EditIcon
                width="28"
                height="28"
                className="EditorPage_hover-button"
                fill="var(--highlightColor)"
              />
            </button>
          )}
          {showDateEditor && (
            <form onSubmit={handleSubmit}>
              <input
                type="date"
                value={newDate}
                onChange={(e) => setNewDate(e.target.value)}
              />
              <button>Submit</button>
            </form>
          )}
        </div>
      </div>
      {date.introduction && date.introduction.length > 0 && (
        <div className="flex-row align-end">
          <div className="flex-1">
            <PrayerListParser prayer={date.introduction} />
          </div>
          <button
            className="button-reset"
            onClick={() => setShowIntroEditor(true)}
          >
            <EditIcon
              width="28"
              height="28"
              fill="var(--highlightColor)"
              className="EditorPage_hover-button"
            />
          </button>
        </div>
      )}
      {!date.introduction?.length && (
        <button className="outlined" onClick={() => setShowIntroEditor(true)}>
          Add Introduction
        </button>
      )}
      <div className="EditorPage_divider" />
      {date.prayer && date.prayer.length > 0 && (
        <div className="flex-row align-end">
          <div className="flex-1">
            <PrayerListParser prayer={date.prayer} />
          </div>
          <button
            className="button-reset"
            onClick={() => setShowPrayerEditor(true)}
          >
            <EditIcon
              width="28"
              height="28"
              fill="var(--highlightColor)"
              className="EditorPage_hover-button"
            />
          </button>
        </div>
      )}
      {!date.prayer?.length && (
        <button className="outlined" onClick={() => setShowPrayerEditor(true)}>
          Add Prayer
        </button>
      )}
      {showIntroEditor && (
        <BaseDialog onDismiss={() => setShowIntroEditor(false)}>
          <h2 className="m-0">
            Edit prayer for {formatDate(new Date(date.date))}
          </h2>
          <PrayerCycleFormEditor
            prayer={
              date.introduction?.length > 0
                ? date.introduction
                : [{ type: "paragraph", editable_text: "", text: "" }]
            }
            onSubmit={(intro) => {
              if (intro.length === 1 && intro[0].text === "") {
                onChange({ ...date, introduction: null });
              } else {
                onChange({ ...date, introduction: intro });
              }
              setShowIntroEditor(false);
            }}
            onDismiss={() => setShowIntroEditor(false)}
          />
        </BaseDialog>
      )}
      {showPrayerEditor && (
        <BaseDialog onDismiss={() => setShowPrayerEditor(false)}>
          <h2 className="m-0">
            Edit prayer for {formatDate(new Date(date.date))}
          </h2>
          <PrayerCycleFormEditor
            prayer={
              date.prayer?.length > 0
                ? date.prayer
                : [{ type: "paragraph", editable_text: "", text: "" }]
            }
            onSubmit={(p) => {
              if (p.length === 1 && p[0].text === "") {
                onChange({ ...date, prayer: null });
              } else {
                onChange({ ...date, prayer: p });
              }
              setShowPrayerEditor(false);
            }}
            onDismiss={() => setShowPrayerEditor(false)}
          />
        </BaseDialog>
      )}
    </div>
  );
}

function DeleteButton({ onDelete }) {
  return (
    <Menu>
      <MenuButton className="button-reset">
        <DeleteIcon fill="var(--negativeColor)" />
      </MenuButton>
      <MenuPopover className="DeleteButton_popover">
        Are you sure?
        <MenuItems>
          <MenuItem onSelect={() => {}} className="button">
            Cancel
          </MenuItem>
          <MenuItem onSelect={onDelete} className="button destructive">
            Delete
          </MenuItem>
        </MenuItems>
      </MenuPopover>
    </Menu>
  );
}
