import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useAccount } from "../../AccountContext";
import { logout } from "../../api";
import { useTheme } from "../../contexts/ThemeContext";

function LogoutPage() {
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const { setAccount } = useAccount();

  useEffect(() => {
    logout().finally(() => {
      setAccount(undefined);
      setTimeout(() => {
        setIsLoggedOut(true);
      }, 100);
    });
  }, []);

  if (isLoggedOut) return <Redirect to="/" />;

  return null;
}

export default LogoutPage;
