import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getMyPrayerCycle,
  useMyPrayerCycleQuery,
  usePrayerCycleQuery,
} from "../../api";
import ErrorMessage from "../../components/ErrorMessage";
import Spinner from "../../components/Spinner";
import PrayerCycleEditor from "../PrayerCycleEditor";

function MyCyclePage() {
  const { prayer_cycle_id } = useParams();
  const [status, setStatus] = useState("success");
  const [error, setError] = useState(undefined);
  // const { data } = useMyPrayerCycleQuery(prayer_cycle_id, {
  //   enabled: !!prayer_cycle_id,
  // });
  const [data, setData] = useState(undefined);
  useEffect(() => {
    getMyPrayerCycle(prayer_cycle_id)
      .then(setData)
      .catch((e) => {
        setError(e);
        setStatus("error");
      });
  }, [prayer_cycle_id]);

  if (status === "error") {
    return (
      <ErrorMessage
        titles={{ default: "Not Found!" }}
        messages={{ 404: "Sorry, we couldn’t find that prayer cycle." }}
        code={error?.message}
      />
    );
  }
  if (!data) return <Spinner />;
  return <PrayerCycleEditor prayerCycle={data} />;
}

export default MyCyclePage;
